import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { datadogRum } from 'modules/datadog';

// ECONNABORTED: Request aborted by the browser.
// Reference: https://github.com/axios/axios/blob/b3be36585884ba1e237fdd0eacf55f678aefc396/lib/adapters/xhr.js#L152
// ERR_NETWORK: Network error sometimes is triggered when the user navigated away quickly after the scheduler is initiated
// Reference: https://github.com/axios/axios/blob/b3be36585884ba1e237fdd0eacf55f678aefc396/lib/adapters/xhr.js#L162
const errorCodesToIgnoreForErrorScreen = [AxiosError.ECONNABORTED, AxiosError.ERR_NETWORK, AxiosError.ERR_CANCELED];
const errorCodesToIgnoreForDatadog = [AxiosError.ECONNABORTED, AxiosError.ERR_CANCELED];

const createAxiosInstance = (config?: AxiosRequestConfig<unknown>): AxiosInstance => {
    const instance = axios.create({
        timeout: 30_000, // 30 seconds.
        transitional: {
            clarifyTimeoutError: true,
        },
        ...config,
    });

    instance.interceptors.response.use(
        (response) => response,
        async (axiosError: Omit<AxiosError, 'request'> & { request?: XMLHttpRequest }) => {
            const { response, message, code, request } = axiosError;

            if (code && errorCodesToIgnoreForErrorScreen.includes(code)) {
                Object.defineProperty(axiosError, '__ignoreErrorBoundary', { value: true });
            }

            if (!code || !errorCodesToIgnoreForDatadog.includes(code)) {
                const apiErrorMessage = (request?.response as unknown) ?? response?.data;

                const {
                    url = 'UNKNOWN_URL',
                    baseURL = 'UNKNOWN_BASE_URL',
                    method = 'UNKNOWN_METHOD',
                } = axiosError.config ?? response?.config ?? {};
                datadogRum.addError(axiosError, {
                    apiErrorMessage,
                    message,
                    method,
                    status: response?.status ?? 'UNKNOWN_STATUS',
                    apiUrl: `${baseURL}${url}`,
                    code,
                });
            }

            return Promise.reject(axiosError);
        }
    );

    return instance;
};

export { createAxiosInstance };
