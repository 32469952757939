import styled from 'styled-components';

import { Props } from '.';

const Container = styled.div<{ $status: Props['status']; $hasTitleAndContent: boolean }>`
    display: flex;
    gap: 0.5rem;
    align-items: ${({ $hasTitleAndContent }) => ($hasTitleAndContent ? 'unset' : 'center')};
    padding: 1rem;
    border-radius: 4px;
    background: ${({ $status, theme }) =>
        $status === 'warning' ? theme.vui.colors.surface.attention : theme.vui.colors.background.blue};
`;

const Content = styled.div<{ $hasTitle: boolean; $variant: Props['variant'] }>`
    width: 100%;
    margin-top: ${({ $hasTitle, $variant }) => ($hasTitle && $variant !== 'small' ? '0.5rem' : '0')};
`;

const ContentWrapper = styled.div`
    width: 100%;
`;

export { Container, Content, ContentWrapper };
