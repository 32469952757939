import { Autocomplete, AutocompleteOption, AutocompleteProps } from '@village/ui';
import { sortBy, prop, values } from 'ramda';
import { useId, useMemo, useCallback, forwardRef, ReactElement } from 'react';

import { useBooking, useCaptureCountlyEvent } from 'hooks';
import { getHyphenatedDepartmentAddress } from 'utils/department';
import { Department } from 'types';

interface DepartmentOption extends AutocompleteOption {
    departmentId: number;
}

interface Props extends Omit<AutocompleteProps<DepartmentOption, false>, 'onChange' | 'options' | 'value'> {
    label?: string;
    departments: Department[] | undefined;
}

const DepartmentField = forwardRef<HTMLDivElement, Props>(({ label, id, departments, ...props }, ref): ReactElement => {
    const localId = useId();
    const { booking, setBookingField } = useBooking();
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { isExistingPatient, market } = booking;

    const options = useMemo(() => {
        const allRelatedDepartments = values(market?.related_departments ?? {}).flatMap(
            (relatedDepartments) => relatedDepartments
        );

        const schedulableDepartments = market?.schedulable_department_ids ?? [];

        const unsortedOptions =
            departments
                // 1. Filter out departments that are not schedulable for the market.
                // 2. Filter out departments that are configured as related departments.
                //    So that they are not shown in the dropdown.
                ?.filter(
                    ({ departmentid }) =>
                        schedulableDepartments.includes(departmentid) && !allRelatedDepartments.includes(departmentid)
                )
                .map<DepartmentOption>((department) => ({
                    departmentId: department.departmentid,
                    value: department.departmentid.toString(),
                    label: getHyphenatedDepartmentAddress(department),
                })) ?? [];

        return sortBy(prop('label'), unsortedOptions);
    }, [departments, market]);

    const handleOnChange = useCallback(
        (option: DepartmentOption | null) => {
            if (!booking.department) {
                addCountlyEvent({ key: 'selectLocation' });
            }

            const department = departments?.find(({ departmentid }) => option?.departmentId === departmentid);
            setBookingField({ department });
        },
        [departments, booking.department, setBookingField, addCountlyEvent]
    );

    const selectedOption = options.find((option) => option.departmentId === booking.department?.departmentid);

    return (
        <div ref={ref}>
            <Autocomplete
                id={id ?? localId}
                aria-label={label}
                blurInputOnSelect={true}
                label={label}
                isDisabled={isExistingPatient === undefined}
                options={options}
                onChange={handleOnChange}
                value={selectedOption ?? null}
                noOptionsMessage={() => 'No locations available'}
                placeholder="Select your preferred location"
                {...props}
            />
        </div>
    );
});

export { DepartmentField };
