import { prop } from 'ramda';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';

import { aceApiV2 } from 'api/ace';
import type { Booking, AceProvider } from 'types';
import { useAppOptions } from './use-app-options';

const useAceProviders = (booking: Booking): UseQueryResult<AceProvider[]> => {
    const appOptions = useAppOptions();
    const { market, departmentAndRelatedDepartments } = booking;
    const departmentsIdsString = departmentAndRelatedDepartments.map(prop('departmentid')).join();

    const params = {
        department_ids: departmentsIdsString,
        market: market?.market_key,
    };

    return useQuery({
        queryKey: ['providers', departmentsIdsString],
        queryFn: async ({ signal }) => {
            const { data } = await aceApiV2.get<{ providers: AceProvider[] }>('/providers', { signal, params });

            // Do not display non-person providers on provider widget
            // This is never expected, but just sanity check
            if (appOptions.type === 'providerWidget') {
                return data.providers.filter(({ entitytype }) => entitytype === 'Person');
            }

            return data.providers;
        },
        enabled: Boolean(market?.market_key && departmentsIdsString),
    });
};

export { useAceProviders };
