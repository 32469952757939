import { createContext } from 'react';

import type { Booking } from 'types';

export interface BookingContextType {
    readonly booking: Booking;
    readonly setBookingField: (partialBooking: Partial<Booking>) => void;
    readonly getBooking: () => Booking;
}

const BookingContext = createContext<BookingContextType | undefined>(undefined);

BookingContext.displayName = 'Booking';

export { BookingContext };
