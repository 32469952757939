import { useMutation } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';

import { aceApi } from 'api/ace';
import { Booking } from 'types';
import { toggleFrozenAppointmentInStorage } from 'utils/appointment';
import { useAppOptions } from './use-app-options';

export interface UseFreezeAppointmentParams {
    appointmentid: number;
    freeze: boolean;
}

const useFreezeAppointment = (booking: Booking): UseMutationResult<unknown, unknown, UseFreezeAppointmentParams> => {
    const { practice } = useAppOptions();

    return useMutation({
        mutationKey: ['freeze-appointment'],
        mutationFn: async ({ appointmentid, freeze }) => {
            if (!booking.market) {
                return;
            }

            const response = await aceApi.post<unknown>(
                `${booking.market.market_key}/${practice}/athena/appointments/${appointmentid}/freeze`,
                { freeze }
            );

            // useMutation's `onSuccess` has a bug where the callback is called even without the query to ace
            toggleFrozenAppointmentInStorage({ appointmentid, freeze });

            return response.data;
        },
    });
};

export { useFreezeAppointment };
