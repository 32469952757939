import { Text } from '@village/ui';
import styled from 'styled-components';

const ErrorBoundaryContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    padding: 2rem;
    max-width: 40rem;
    margin: 0 auto;
`;

const ErrorBoundaryWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const ErrorMessage = styled(Text).attrs(() => ({ type: 'h3' }))`
    display: grid;
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

const Hint = styled(Text).attrs(() => ({ type: 'body1' }))`
    display: grid;
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    margin-bottom: 1.5rem;
`;

export { ErrorBoundaryContainer, ErrorBoundaryWrapper, ErrorMessage, Hint };
