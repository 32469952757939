import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';

import { hubspotApi } from 'api/hubspot';
import { appConfig } from 'modules/app-config';
import type { HubspotResponse, HubspotLocation, Booking } from 'types';
import { hubspotLocationFactory } from 'utils/location';

const useHubspotLocations = (booking: Booking): UseQueryResult<HubspotLocation[]> => {
    const marketKey = booking.market?.market_key;
    return useQuery({
        queryKey: ['hubspot-locations', marketKey],
        queryFn: async (): Promise<HubspotLocation[]> => {
            try {
                const { data } = await hubspotApi.get<HubspotResponse>(`tables/${appConfig.hubspotLocationsTableId}/rows`, {
                    params: {
                        portalId: appConfig.hubspotPortalId,
                        market_key__eq: marketKey,
                    },
                });

                return data.results.map((result) => hubspotLocationFactory(result));
            } catch {
                return [];
            }
        },
        enabled: Boolean(marketKey && booking.isExistingPatient !== undefined),
        staleTime: Infinity,
        throwOnError: false,
    });
};

export { useHubspotLocations };
