import { Text } from '@village/ui';
import styled from 'styled-components';

const Title = styled(Text).attrs(() => ({ type: 'caption1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    font-weight: 700;
`;

const Content = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

export { Title, Content };
