import { FC, PropsWithChildren, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { isPast } from 'date-fns';

import { useAppOptions, useCareTeam, useDepartments, useFreezeAppointment, useProviders } from 'hooks';
import type { Booking } from 'types';
import { getRelatedDepartments } from 'utils/department';
import { BookingContext, BookingContextType } from './booking-context';
import { getFrozenAppointmentsIds } from 'utils/appointment';

interface Props {
    bookingInitial?: Partial<Booking>;
}

const BookingContextProvider: FC<PropsWithChildren<Props>> = ({ children, bookingInitial }) => {
    const [booking, setBooking] = useState<Booking>({
        appointment: undefined,
        date: new Date(),
        department: undefined,
        market: undefined,
        provider: undefined,
        providersAndCareTeams: [],
        departmentAndRelatedDepartments: [],
        reason: undefined,
        isExistingPatient: undefined,
        patientInformation: undefined,
        reasonDetails: '',
        previouslySelectedReason: undefined,
        patientIdentification: undefined,
        ...bookingInitial,
    });

    const { type } = useAppOptions();
    const { data: providers } = useProviders(booking);
    const { data: careTeam } = useCareTeam(booking);
    const { data: departments } = useDepartments(booking);
    const { mutateAsync: freezeAppointmentMutation } = useFreezeAppointment(booking);

    // Because booking state is rendered asynchronously, we save the new booking object in a ref,
    // which is consumed by the provider and reflected immediately for usage in components
    const latestBookingState = useRef(booking);

    const setBookingField: BookingContextType['setBookingField'] = useCallback(
        (partialBooking) => {
            const newState = { ...latestBookingState.current, ...partialBooking };

            const relatedDepartments = getRelatedDepartments(newState.department, newState.market, departments);
            latestBookingState.current = {
                ...newState,
                // if the date is in the past, set it to today
                date: isPast(newState.date) ? new Date() : newState.date,
                // Combine selected department and related departments
                departmentAndRelatedDepartments: newState.department ? [newState.department, ...relatedDepartments] : [],
            };

            setBooking(latestBookingState.current);
        },
        [departments]
    );

    const getBooking: BookingContextType['getBooking'] = useCallback((): Booking => latestBookingState.current, []);

    useEffect(() => {
        const frozenAppointments = getFrozenAppointmentsIds();
        if (booking.appointment && !frozenAppointments.includes(booking.appointment.appointmentid)) {
            freezeAppointmentMutation({ appointmentid: booking.appointment.appointmentid, freeze: true }).catch(() => {
                // Remove appointment from booking state if we couldn't freeze it
                setBooking((state) => ({ ...state, appointment: undefined }));
            });
        }

        if (type === 'citymd' && !booking.appointment && frozenAppointments.length > 0) {
            frozenAppointments.forEach((appointmentid) => {
                freezeAppointmentMutation({ appointmentid, freeze: false });
            });
        }
    }, [booking.appointment, freezeAppointmentMutation, type]);

    const bookingContextValue = useMemo(
        () => ({
            booking: {
                ...booking,
                // Combine selected provider with their care team
                // If there is no provider selected, use all the providers
                providersAndCareTeams: (booking.provider ? [booking.provider, ...(careTeam ?? [])] : providers) ?? [],
            },
            setBookingField,
            getBooking,
        }),
        [booking, careTeam, providers, setBookingField, getBooking]
    );

    return <BookingContext.Provider value={bookingContextValue}>{children}</BookingContext.Provider>;
};

export { BookingContextProvider };
