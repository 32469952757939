import { createContext } from 'react';

import { Countly } from 'modules/countly';

export interface CaptureCountlyEvents {
    addCountlyEvent: typeof Countly.addEvent;
}

const CaptureCountlyEventsContext = createContext<CaptureCountlyEvents | undefined>(undefined);

CaptureCountlyEventsContext.displayName = 'CaptureCountlyEventsContext';

export { CaptureCountlyEventsContext };
