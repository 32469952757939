import { useState } from 'react';

import { useAppOptions } from './use-app-options';

export type SchedulingUnavailableReason = 'NO_PROVIDER' | 'NO_DEPARTMENT' | 'NO_MARKET' | 'NO_REASONS_NEW_PATIENTS' | 'DISABLED';

export const useSchedulingNotAvailable = () => {
    const widgetOptions = useAppOptions<'providerWidget' | 'locationWidget'>();

    const [schedulingNotAvailableReason, setSchedulingUnavailableReason] = useState<SchedulingUnavailableReason | null>(
        !widgetOptions.enabledForScheduling ? 'DISABLED' : null
    );

    return {
        schedulingNotAvailableReason,
        setSchedulingUnavailableReason,
    };
};
