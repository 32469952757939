import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;

    > * {
        text-align: center;
    }
`;

const Title = styled(Text).attrs(() => ({ type: 'h4', fontWeight: 300 }))`
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const SubTitle = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;

export { Container, Title, SubTitle };
