import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { sort, splitEvery } from 'ramda';

import { aceApi } from 'api/ace';
import { Appointment, AppointmentApi, Booking } from 'types';
import { appointmentFactory, sortAppointmentsByFirstAvailable } from 'utils/appointment';
import { NEXT_AVAILABLE_APPOINTMENTS_QUERIES_BATCH_SIZE } from 'constants/appointment';

interface QueryParams {
    marketKey: string | undefined;
    departmentIds: number[];
    reasonId: number | null | undefined;
    providerIds: number[];
    bypassScheduleTimeChecks: boolean;
    range: number;
    startDate: string;
}

const useNextAvailableAppointments = (
    { departmentAndRelatedDepartments, reason }: Booking,
    inputParams: QueryParams,
    options?: Partial<UseQueryOptions<Appointment[]>>
): UseQueryResult<Appointment[]> => {
    return useQuery({
        queryKey: ['next-available-appointments', inputParams],
        queryFn: async ({ signal }) => {
            // Do not use Async/Await.
            // Using Async/Await creates a new promise without `cancel`, so the query cannot be cancelled.
            const queries = inputParams.departmentIds.flatMap((departmentId) => {
                const batchedProviders = splitEvery(NEXT_AVAILABLE_APPOINTMENTS_QUERIES_BATCH_SIZE, inputParams.providerIds);

                return batchedProviders.map((providerBatch) => {
                    const params = {
                        bypass_schedule_time_checks: inputParams.bypassScheduleTimeChecks,
                        department_id: departmentId,
                        provider_id: providerBatch.join(','),
                        range: inputParams.range,
                        reason_id: inputParams.reasonId,
                        start_date: inputParams.startDate,
                    };

                    return aceApi.get<{ appointments: AppointmentApi[] }>(
                        `/${inputParams.marketKey}/villagefamilypractice/athena/appointments/next-available`,
                        { params, signal }
                    );
                });
            });

            return await Promise.all(queries).then((responses) => {
                const nextAppointments = responses
                    .flatMap(({ data }) => data.appointments)
                    // For some reason, backend might return undefined/null sometimes
                    // Reference https://app.datadoghq.com/rum/error-tracking/issue/08c27aac-5bd6-11ee-806a-da7ad0900002
                    .filter((appointment) => appointment)
                    .map((appointment) => appointmentFactory(appointment, departmentAndRelatedDepartments, reason));

                return sort(sortAppointmentsByFirstAvailable, nextAppointments);
            });
        },
        ...options,
        enabled: Boolean(
            options?.enabled !== false &&
                inputParams.marketKey &&
                inputParams.reasonId &&
                inputParams.startDate &&
                inputParams.range &&
                inputParams.departmentIds.length > 0 &&
                inputParams.providerIds.length > 0
        ),
        // Disable Error Boundary to prevent the error from being caught by the Error Boundary.
        // This is an optimistic query, so we don't want to show an error if it fails.
        throwOnError: false,
    });
};

export { useNextAvailableAppointments };
export type { QueryParams };
