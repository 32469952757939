import { FC, PropsWithChildren } from 'react';

import type { AppOptions } from 'types';
import { AppOptionsContext } from './app-options-context';

const AppOptionsContextProvider: FC<PropsWithChildren<{ options: AppOptions }>> = ({ children, options }) => {
    return <AppOptionsContext.Provider value={options}>{children}</AppOptionsContext.Provider>;
};

export { AppOptionsContextProvider };
