const sizes = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560,
};

const device = {
    minMobileS: `screen and (min-width: ${sizes.mobileS + 1}px)`,
    minMobileM: `screen and (min-width: ${sizes.mobileM + 1}px)`,
    minMobileL: `screen and (min-width: ${sizes.mobileL + 1}px)`,
    minTablet: `screen and (min-width: ${sizes.tablet + 1}px)`,
    minLaptop: `screen and (min-width: ${sizes.laptop + 1}px)`,
    minLaptopL: `screen and (min-width: ${sizes.laptopL + 1}px)`,
    minDesktop: `screen and (min-width: ${sizes.desktop + 1}px)`,
    minDesktopL: `screen and (min-width: ${sizes.desktop + 1}px)`,
    maxMobileS: `screen and (max-width: ${sizes.mobileS}px)`,
    maxMobileM: `screen and (max-width: ${sizes.mobileM}px)`,
    maxMobileL: `screen and (max-width: ${sizes.mobileL}px)`,
    maxTablet: `screen and (max-width: ${sizes.tablet}px)`,
    maxLaptop: `screen and (max-width: ${sizes.laptop}px)`,
    maxLaptopL: `screen and (max-width: ${sizes.laptopL}px)`,
    maxDesktop: `screen and (max-width: ${sizes.desktop}px)`,
    maxDesktopL: `screen and (max-width: ${sizes.desktop}px)`,
};

export { device, sizes };
