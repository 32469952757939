import { AxiosError, isAxiosError } from 'axios';
import { path } from 'ramda';
import { useEffect } from 'react';

import { Countly } from 'modules/countly';
import { useAppOptions } from 'hooks';
import { datadogLogs, datadogRum } from 'modules/datadog';

const useErrorBoundaryMonitoring = (error: AxiosError | Error) => {
    const { type } = useAppOptions();

    useEffect(() => {
        const endpoint = isAxiosError(error) ? `${error.config?.baseURL ?? '/'}${error.config?.url ?? ''}` : undefined;
        const apiResponse = isAxiosError(error) ? (error.response?.data as Record<string, unknown>) : undefined;
        const errorMessage = path<string>(['detail'], apiResponse) || path<string>(['error'], apiResponse) || error.message;
        // Sometimes backend includes details error message about why the request failed
        const detailedErrorMessage = path<string>(['detailedmessage', 'detailedmessage'], apiResponse);

        Countly.addEvent({
            key: 'errorScreen',
            segmentation: {
                endpoint,
                errorName: error.name,
                errorMessage,
                detailedErrorMessage,
                schedulerType: type,
            },
        });

        // Experimenting with Datadog error tracking (https://docs.datadoghq.com/real_user_monitoring/browser/)
        datadogLogs.logger.error(errorMessage, { error });
        datadogRum.addError(error);
    }, [error, type]);
};

export { useErrorBoundaryMonitoring };
