import { useToggle } from '@village/tools';
import { Link, Text } from '@village/ui';
import { FC, Fragment, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { WIDGET_SLOTS_PER_COLUMN } from 'constants/appointment';
import { Appointment, AppointmentsByDepartmentProvider } from 'types';
import { useAppOptions, useAppointmentsRange, useBooking, useCareTeam } from 'hooks';
import { CareTeamHeader } from 'components/care-team-header';
import { NextAvailableAppointments } from 'components/next-available-appointments';
import { buildAppointmentsByDateCollection } from 'widget/utils/date-collection';
import { AppointmentsColumn } from './appointments-column';
import { PrnBadge } from 'components/prn-badge';
import * as Styled from './styles';

export interface Props {
    appointmentsGroup: AppointmentsByDepartmentProvider;
    isCareTeamMember: boolean;
    isAppointmentsFetching: boolean;
    onSelectAppointment: (appointment: Appointment) => void;
}

const AppointmentsCard: FC<Props> = ({ appointmentsGroup, isAppointmentsFetching, onSelectAppointment }) => {
    const cardRef = useRef<HTMLDivElement | null>(null);
    const { booking } = useBooking();
    const appOptions = useAppOptions();
    const { datesRange } = useAppointmentsRange();
    const { data: careTeam } = useCareTeam(booking);
    const { date: bookingDate, provider: selectedProvider } = booking;
    const { provider, appointments, showCareTeamHeader } = appointmentsGroup;
    const { switchOff: showLess, switchOn: showMore, value: showAllAvailability } = useToggle(false);

    // If the provider has a hubspot path, and the provider is in the care team or the widget is not a provider widget
    // then display the provider link.
    const displayProviderLink = Boolean(
        !provider.isprn && provider.hubspot?.path && (careTeam?.includes(provider) || appOptions.type !== 'providerWidget')
    );

    // To avoid queries waterfall when user quickly scrolls thought the cards.
    // Delay of 1 second before triggering the next available appointments query for the inView card.
    const { ref, inView } = useInView({ threshold: 0.3, delay: 1000, triggerOnce: true });

    const appointmentsByDate = useMemo(
        () => buildAppointmentsByDateCollection(bookingDate, datesRange, appointments),
        [bookingDate, datesRange, appointments]
    );

    // The appointments for each date have already been padded with empty slots to fill the column.
    // Therefore, we can use the first date to get the number of appointments per column.
    const appointmentCountPerColumn = appointmentsByDate[0].appointments.length;
    const slotsCount = showAllAvailability ? appointmentCountPerColumn : WIDGET_SLOTS_PER_COLUMN;

    const handleShowLessAvailability = () => {
        showLess();
        cardRef.current?.scrollIntoView({ block: 'center' });
    };

    return (
        <Styled.Container ref={ref} data-provider-id={provider.providerid}>
            {showCareTeamHeader && selectedProvider ? <CareTeamHeader provider={selectedProvider} /> : null}
            <div>
                <Styled.ProviderContainer
                    $hoverEffect={displayProviderLink}
                    as={displayProviderLink ? 'a' : 'div'}
                    href={displayProviderLink ? provider.hubspot?.path : undefined}
                >
                    <Styled.ProviderImage src={provider.thumbnailUrl} />
                    <Styled.ProviderDetails ref={cardRef}>
                        <Styled.ProviderName>{provider.displayname}</Styled.ProviderName>
                        <Styled.ProviderSpecialty>{provider.specialty}</Styled.ProviderSpecialty>
                        {provider.isprn ? (
                            <Styled.PrnNotice>
                                <PrnBadge />
                            </Styled.PrnNotice>
                        ) : null}
                    </Styled.ProviderDetails>
                </Styled.ProviderContainer>
                {!isAppointmentsFetching ? (
                    <Fragment>
                        {appointments.length > 0 ? (
                            <Styled.AvailabilityContainer>
                                <Styled.AppointmentsContainer>
                                    {appointmentsByDate.map(({ date, appointments: dateAppointments }) => (
                                        <AppointmentsColumn
                                            key={date}
                                            date={date}
                                            appointments={dateAppointments}
                                            onSelectAppointment={onSelectAppointment}
                                            slotsCount={slotsCount}
                                        />
                                    ))}
                                </Styled.AppointmentsContainer>

                                {appointmentCountPerColumn > WIDGET_SLOTS_PER_COLUMN ? (
                                    <Styled.AvailabilityControllerWrapper>
                                        {!showAllAvailability ? (
                                            <Link onClick={showMore}>
                                                View all availability <Styled.ViewAllAvailabilityIcon />
                                            </Link>
                                        ) : (
                                            <Link onClick={handleShowLessAvailability}>
                                                View less availability <Styled.ViewLessAvailabilityIcon />
                                            </Link>
                                        )}
                                    </Styled.AvailabilityControllerWrapper>
                                ) : null}
                            </Styled.AvailabilityContainer>
                        ) : (
                            <Styled.NoAppointmentsContainer>
                                {provider.entitytype === 'Person' ? (
                                    <NextAvailableAppointments grouping={appointmentsGroup} isInView={inView} />
                                ) : (
                                    <Text type="body1">No available appointments for the selected dates.</Text>
                                )}
                            </Styled.NoAppointmentsContainer>
                        )}
                    </Fragment>
                ) : null}
            </div>
        </Styled.Container>
    );
};

export { AppointmentsCard };
