import { Input, InputProps } from '@village/ui';
import { FC } from 'react';
import { useController } from 'react-hook-form';
import { IMaskMixinProps, IMaskMixin } from 'react-imask';

type MaskedInputProps = InputProps &
    IMaskMixinProps<HTMLInputElement> & {
        label: string | undefined;
        name: string;
        type?: Exclude<InputProps['type'], 'textarea'>;
    };

const MaskedInputForm: FC<MaskedInputProps> = (props) => {
    const {
        field: { onChange, ref, value, ...field },
        fieldState: { error },
    } = useController({ name: props.name, disabled: props.disabled });

    return (
        <MaskedInputFormField
            {...props}
            error={error?.message}
            onAccept={onChange}
            inputRef={ref}
            value={value ?? ''}
            {...field}
        />
    );
};

/**
 * Type documentation for this package is inadequate,
 * so we are using what is recommended by the package owner:
 * https://github.com/uNmAnNeR/imaskjs/issues/654#issuecomment-1245411575
 */
const MaskedInputFormField = IMaskMixin<
    HTMLInputElement, // wrapped element type
    MaskedInputProps // custom props
>(({ inputRef, ...props }) => <Input {...props} ref={inputRef} />);

export { MaskedInputForm };
