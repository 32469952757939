import { uniqBy, sortBy } from 'ramda';
import { BuildingIcon, CameraOutlinedIcon } from '@village/icons';
import { Autocomplete, AutocompleteOption, AutocompleteProps } from '@village/ui';
import { ChangeEvent, FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useAppOptions, useBooking, useCaptureCountlyEvent } from 'hooks';
import { Reason, VisitType } from 'types';
import { VisitTypeBanner } from 'components/visit-type-banner';
import * as Styled from './styles';
import { sortReasons } from 'utils/reason';

export interface ReasonOption extends AutocompleteOption {
    reasonId: number;
}

interface Props extends Omit<AutocompleteProps<ReasonOption, false>, 'onChange' | 'options' | 'value'> {
    reasons: Reason[];
    isReasonsFetching: boolean;
}

const TEXT: Record<'locationWidget' | 'providerWidget', Record<'videoReasonsOnly' | 'inOfficeReasonsOnly', string>> = {
    providerWidget: {
        videoReasonsOnly: 'This provider only sees video visits at this location. In-office visits are not available.',
        inOfficeReasonsOnly: 'This provider only sees in-office visits at this location. Video visits are not available.',
    },
    locationWidget: {
        videoReasonsOnly: 'Only video visits are available at this location. In-office visits are not available.',
        inOfficeReasonsOnly: 'Only in-office visits are available at this location. Video visits are not available.',
    },
};

const ReasonField: FC<Props> = ({ label, id, reasons, isReasonsFetching, ...props }) => {
    const { booking, setBookingField } = useBooking();
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { type } = useAppOptions<'locationWidget' | 'providerWidget'>();
    // We default to in-office visit type because all the default reasons are in-office
    const [visitType, setVisitType] = useState<VisitType>('IN_OFFICE');

    const { videoReasonsOnly, inOfficeReasonsOnly, oneVisitTypeOnly } = useMemo(() => {
        return {
            videoReasonsOnly: reasons.every((reason) => !reason.is_in_person),
            inOfficeReasonsOnly: reasons.every((reason) => reason.is_in_person),
            // When only a single visit type is available for the selected reason
            oneVisitTypeOnly: reasons.filter((reason) => reason.name === booking.reason?.name).length === 1,
        };
    }, [reasons, booking.reason]);

    const handleVisitTypeChange = useCallback(
        (event: ChangeEvent<object>) => {
            const visitTypeNew = (event as ChangeEvent<HTMLInputElement>).target.value as VisitType;
            const newReason = reasons.find((reason) => reason.name === booking.reason?.name && reason.visitType === visitTypeNew);

            if (newReason) {
                setBookingField({ reason: newReason });
                setVisitType(visitTypeNew);
            }

            addCountlyEvent({ key: 'updateVisitType' });
        },
        [reasons, booking.reason?.name, setBookingField, addCountlyEvent]
    );

    const reasonOptions = useMemo(() => {
        // Sort the reasons by most used first
        const reasonSorted = sortReasons(reasons ?? []);

        return (
            uniqBy<Reason, Reason['name']>(
                (reason) => reason.name,
                // Sort again the reasons so that the selected visitType reason is always first
                // This is to ensure that the selected reason is always visible in the dropdown
                sortBy((reason) => reason.visitType !== visitType, reasonSorted)
            ).map<ReasonOption>((reason) => ({
                label: reason.name,
                reasonId: reason.id,
                value: reason.id.toString(),
            })) ?? []
        );
    }, [reasons, visitType]);

    const selectedOption = reasonOptions.find(({ reasonId }) => booking.reason && booking.reason.id === reasonId);

    const handleReasonChange = useCallback(
        (option: ReasonOption | null) => {
            const reason = reasons.find((reasonItem) => option?.reasonId === reasonItem.id);
            setBookingField({ reason });
            addCountlyEvent({ key: 'updateReason' });
        },
        [reasons, setBookingField, addCountlyEvent]
    );

    useEffect(() => {
        if (!reasons || reasons.length === 0) {
            return;
        }

        // Select the reason that matches the title and visit type
        // but different object reference (i.e. Different department)
        const reason = reasons.find(
            (reasonItem) =>
                booking.reason && reasonItem.name === booking.reason.name && booking.reason.visitType === reasonItem.visitType
        );

        if (booking.reason !== reason) {
            setBookingField({ reason });
        }
    }, [selectedOption, booking.reason, reasons, setBookingField, visitType]);

    return (
        <Fragment>
            <Autocomplete
                aria-label={label}
                blurInputOnSelect={true}
                id="select-reason"
                label="Reason for Visit:"
                isDisabled={booking.isExistingPatient === undefined || booking.department === undefined}
                isSearchable={false}
                options={reasonOptions}
                onChange={handleReasonChange}
                value={selectedOption ?? null}
                noOptionsMessage={() => 'No reasons available'}
                placeholder="Select reason for appointment"
                menuShouldScrollIntoView={true}
                isLoading={isReasonsFetching}
                {...props}
            />
            {booking.reason && reasons && reasons.length > 0 ? (
                <div>
                    <Styled.Label>Visit type:</Styled.Label>
                    {!videoReasonsOnly && !inOfficeReasonsOnly && !oneVisitTypeOnly ? (
                        <Styled.ValuesContainer data-role="radiogroup">
                            <Styled.RadioInput
                                id="visit-type-radio-in-office"
                                label="In-office"
                                onChange={handleVisitTypeChange}
                                data-testid="visit-type-radio-in-office"
                                name="visitType"
                                value="IN_OFFICE"
                                checked={booking.reason?.is_in_person === true}
                            />
                            <Styled.RadioInput
                                id="visit-type-radio-in-video"
                                label="Video"
                                onChange={handleVisitTypeChange}
                                data-testid="visit-type-radio-video"
                                name="visitType"
                                value="VIDEO"
                                checked={booking.reason?.is_in_person === false}
                            />
                        </Styled.ValuesContainer>
                    ) : (
                        <div>
                            {videoReasonsOnly ? (
                                <VisitTypeBanner
                                    subtitle={TEXT[type]['videoReasonsOnly']}
                                    icon={CameraOutlinedIcon}
                                    iconSize={1}
                                    variant="small"
                                />
                            ) : inOfficeReasonsOnly ? (
                                <VisitTypeBanner
                                    subtitle={TEXT[type]['inOfficeReasonsOnly']}
                                    icon={BuildingIcon}
                                    iconSize={1.25}
                                    variant="small"
                                />
                            ) : oneVisitTypeOnly ? (
                                <VisitTypeBanner
                                    subtitle={`Only ${
                                        booking.reason?.is_in_person ? 'in-office' : 'video'
                                    } visits are available for selected reason for visit.`}
                                    icon={BuildingIcon}
                                    iconSize={1}
                                    variant="small"
                                />
                            ) : null}
                        </div>
                    )}
                </div>
            ) : null}
        </Fragment>
    );
};

export { ReasonField };
