const sizes = {
    mobile: 719,
    tablet: 959,
};

const device = {
    maxTablet: `screen and (max-width: ${sizes.tablet}px)`,
    maxMobile: `screen and (max-width: ${sizes.mobile}px)`,
};

export { device, sizes };
