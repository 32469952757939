import { CalendarColoredIconDeprecated } from '@village/icons';
import { FC, Fragment, useEffect, useMemo } from 'react';

import { SchedulingUnavailableReason, useAppOptions, useCaptureCountlyEvent } from 'hooks';
import { DepartmentHubspotInfo } from 'types';
import * as Styled from './styles';

interface Props {
    reason: SchedulingUnavailableReason;
    location?: DepartmentHubspotInfo;
}

const SchedulingNotAvailable: FC<Props> = ({ location, reason }) => {
    const appOptions = useAppOptions();
    const { addCountlyEvent } = useCaptureCountlyEvent();

    const locationNotSchedulingNewPatients = appOptions.type === 'locationWidget' && reason === 'NO_REASONS_NEW_PATIENTS';
    const providerNotSchedulingNewPatients = appOptions.type === 'providerWidget' && reason === 'NO_REASONS_NEW_PATIENTS';

    const { title, subTitle } = useMemo(() => {
        if (locationNotSchedulingNewPatients) {
            return {
                title: 'No providers at this location are scheduling new patients.',
                subTitle: (
                    <Fragment>
                        To find availability for providers at another clinic close to you, <a href="/locator">click here</a>
                    </Fragment>
                ),
            };
        }

        if (providerNotSchedulingNewPatients) {
            return {
                title: 'This provider is not scheduling new patients.',
                subTitle: location ? (
                    <Fragment>
                        To view availability for other providers at this same location,{' '}
                        <a href={`/locations${location.path}`}>click here</a>
                    </Fragment>
                ) : (
                    'To schedule an appointment, please call the clinic phone number.'
                ),
            };
        }

        return {
            title: 'Scheduling not available',
            subTitle: 'To schedule an appointment, please call the clinic phone number.',
        };
    }, [locationNotSchedulingNewPatients, providerNotSchedulingNewPatients, location]);

    useEffect(() => {
        addCountlyEvent({
            key: 'schedulingNotAvailable',
            segmentation: {
                reason,
                providerId: appOptions.type === 'providerWidget' ? appOptions.providerNpi : undefined,
                departmentId: appOptions.type === 'locationWidget' ? appOptions.departmentId : undefined,
            },
        });
    }, [reason, appOptions, addCountlyEvent]);

    return (
        <Styled.Container>
            <CalendarColoredIconDeprecated size={3} />
            <Styled.Title>{title}</Styled.Title>
            <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        </Styled.Container>
    );
};

export { SchedulingNotAvailable };
