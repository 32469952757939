import styled from 'styled-components';
import { Text } from '@village/ui';

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
`;

const AppointmentsNextAvailableContainer = styled.div`
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;

const NoAvailabilityContainer = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    margin-bottom: 0.5rem;
`;

export { SpinnerContainer, AppointmentsNextAvailableContainer, NoAvailabilityContainer };
