import { DepartmentApi, Department, Booking, WidgetAppOptions, HubspotLocation } from 'types';
import { getDepartmentTimezone } from './date';
import { toTitleCase } from './string';
import { formatStreetAddress } from './address';

const departmentFactory = (department: DepartmentApi, hubspotLocation?: HubspotLocation): Department => ({
    address: department.address,
    address2: department.address2,
    addressCityFirst: department.addressCityFirst,
    addressStreetFirst: department.addressStreetFirst,
    chartsharinggroupid: department.chartsharinggroupid,
    city: department.city,
    clinicalproviderfax: department.clinicalproviderfax,
    clinicals: department.clinicals,
    communicatorbrandid: department.communicatorbrandid,
    creditcardtypes: department.creditcardtypes,
    departmentid: parseInt(String(department.departmentid), 10),
    doesnotobservedst: department.doesnotobservedst,
    ecommercecreditcardtypes: department.ecommercecreditcardtypes,
    fax: department.fax,
    ishospitaldepartment: department.ishospitaldepartment,
    latitude: Number(department.latitude),
    longitude: Number(department.longitude),
    medicationhistoryconsent: department.medicationhistoryconsent,
    name: department.name,
    oneyearcontractmax: department.oneyearcontractmax ? Number(department.oneyearcontractmax) : undefined,
    patientdepartmentname: department.patientdepartmentname,
    phone: department.phone,
    placeofservicefacility: department.placeofservicefacility,
    placeofservicetypeid: Number(department.placeofservicetypeid),
    placeofservicetypename: department.placeofservicetypename,
    portalurl: department.portalurl,
    providergroupid: Number(department.providergroupid),
    providergroupname: department.providergroupname,
    providerlist: department.providerlist.map((providerId) => Number(providerId)),
    singleappointmentcontractmax: department.singleappointmentcontractmax
        ? Number(department.singleappointmentcontractmax)
        : undefined,
    servicedepartment: department.servicedepartment,
    state: department.state,
    timezone: department.timezone,
    timezonename: department.timezonename,
    timezoneoffset: department.timezoneoffset,
    zip: department.zip,
    timezoneInfo: getDepartmentTimezone(department.timezonename),
    hubspot: hubspotLocation,
});

const getRelatedDepartments = (
    selectedDepartment: Booking['department'],
    market: Booking['market'],
    allDepartments: Department[] | undefined
): Department[] => {
    if (!selectedDepartment || !allDepartments) {
        return [];
    }

    const { departmentid } = selectedDepartment;
    const relatedDepartmentsIds = market?.related_departments?.[departmentid] ?? [];

    return allDepartments.filter((department) => relatedDepartmentsIds.includes(department.departmentid));
};

/**
 * Returns a Department address in "City, State - Address 1 Address 2" format
 */
const getHyphenatedDepartmentAddress = (department: Department): string => {
    return department.hubspot
        ? getHubspotHyphenatedDepartmentAddress(department.hubspot)
        : getAceHyphenatedDepartmentAddress(department);
};

const getAceHyphenatedDepartmentAddress = ({ address, address2, city, state }: Department): string => {
    return `${toTitleCase(city)}, ${state} - ${toTitleCase(formatStreetAddress(address, address2))}`;
};

const getHubspotHyphenatedDepartmentAddress = (location: HubspotLocation): string => {
    const streetAddress = formatStreetAddress(location.address, location.address2);
    const streetAddressId = location.departmentAddressId ? `${streetAddress} ${location.departmentAddressId}` : streetAddress;

    return `${toTitleCase(location.city)}, ${location.state} - ${toTitleCase(streetAddressId)}`;
};

/**
 * Returns a Department address in "Address 1, Address 2, City, State Zip (5 digit)" format
 */
const getStandardDepartmentAddress = (department: Department): string => {
    return department.hubspot
        ? getHubspotStandardDepartmentAddress(department.hubspot)
        : getAceStandardDepartmentAddress(department);
};

const getAceStandardDepartmentAddress = ({ address, address2, city, state, zip }: Department): string => {
    return `${toTitleCase(formatStreetAddress(address, address2))}, ${toTitleCase(city)}, ${state} ${zip.slice(0, 5)}`;
};

const getHubspotStandardDepartmentAddress = (location: HubspotLocation): string => {
    const standardAddress = `${toTitleCase(formatStreetAddress(location.address, location.address2))}, ${toTitleCase(
        location.city
    )}, ${location.state} ${location.zipcode.slice(0, 5)}`;

    return location.departmentAddressId ? `${standardAddress} ${location.departmentAddressId}` : standardAddress;
};

const isClinicClosedForToday = (options: WidgetAppOptions): boolean => {
    return Boolean(
        (options.type === 'locationWidget' && options.isClinicClosedForToday) ||
            (options.type === 'providerWidget' && options.departmentsList[0]?.isClinicClosedForToday)
    );
};

export {
    departmentFactory,
    getDepartmentTimezone,
    getHyphenatedDepartmentAddress,
    getRelatedDepartments,
    getStandardDepartmentAddress,
    isClinicClosedForToday,
};
