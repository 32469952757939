import { addDays, isToday, isValid } from 'date-fns';
import { FC, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useAppointmentsRange, useBooking, useCaptureCountlyEvent, useIsMobile } from 'hooks';
import { DatePickerCustomInput } from './custom-input';
import * as Styled from './styles';

interface Props {
    isAppointmentsFetching: boolean;
}

const DatesNavigation: FC<Props> = ({ isAppointmentsFetching }) => {
    const isMobile = useIsMobile();
    const { booking, setBookingField } = useBooking();
    const { date, department, reason } = booking;
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { datesRange } = useAppointmentsRange();

    const isPastDatesDisabled = isToday(date) || isAppointmentsFetching;
    const isFutureDatesDisabled = isAppointmentsFetching;
    const endDate = addDays(date, datesRange - 1);

    const goToNextPeriod = useCallback(() => {
        setBookingField({ date: addDays(date, datesRange) });
        addCountlyEvent({ key: 'updateDate' });
    }, [setBookingField, date, datesRange, addCountlyEvent]);

    const goToPreviousPeriod = useCallback(() => {
        setBookingField({ date: addDays(date, -datesRange) });
        addCountlyEvent({ key: 'updateDate' });
    }, [setBookingField, date, datesRange, addCountlyEvent]);

    const handleDateChange = useCallback(
        (dateNew: Date) => {
            if (isValid(dateNew)) {
                setBookingField({ date: dateNew });
                addCountlyEvent({ key: 'updateDate' });
            }
        },
        [setBookingField, addCountlyEvent]
    );

    return (
        <Styled.Container id="date-picker-container">
            <Styled.NavigationButton disabled={isPastDatesDisabled || !department || !reason} onClick={goToPreviousPeriod}>
                <Styled.LeftIcon $disabled={isPastDatesDisabled} size="sm" />
            </Styled.NavigationButton>
            <div>
                <DatePicker
                    closeOnScroll={!isMobile}
                    dateFormat="MM/dd/yyyy"
                    disabled={!department || !reason}
                    minDate={new Date()}
                    selected={date}
                    withPortal={isMobile}
                    onChange={handleDateChange}
                    customInput={<DatePickerCustomInput endDate={endDate} />}
                />
            </div>
            <Styled.NavigationButton disabled={isFutureDatesDisabled || !department || !reason} onClick={goToNextPeriod}>
                <Styled.RightIcon $disabled={isFutureDatesDisabled || !department || !reason} size="sm" />
            </Styled.NavigationButton>
        </Styled.Container>
    );
};

export { DatesNavigation };
