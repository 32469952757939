import { ChevronLeftIcon, ChevronRightIcon } from '@village/icons';
import styled from 'styled-components';

const Container = styled.section`
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 0.5rem;
    padding: 0.75rem 0;
    margin-top: 1.25rem;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};

    & * {
        font-size: 1.125rem;
    }
`;

const Day = styled.p`
    line-height: 1.2;
`;

const NavigationButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 2.5rem;
    height: 2.5rem;
    width: 2.5rem;
    background: transparent;
    color: ${({ theme }) => theme.vui.colors.text.primary};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    @media (hover: hover) {
        &:hover:not(&:disabled) {
            background: rgba(0, 168, 198, 0.14);
        }
    }
`;

const LeftIcon = styled(ChevronLeftIcon)<{ $disabled: boolean }>`
    color: ${({ theme, $disabled }) => ($disabled ? theme.vui.colors.icon.disabled : theme.vui.colors.icon.primary)};
`;

const RightIcon = styled(ChevronRightIcon)<{ $disabled: boolean }>`
    color: ${({ theme, $disabled }) => ($disabled ? theme.vui.colors.icon.disabled : theme.vui.colors.icon.primary)};
`;

export { Container, Day, NavigationButton, LeftIcon, RightIcon };
