import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.vui.colors.background.gray};
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 8px;
`;

const Content = styled.span`
    flex: 1;
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const Title = styled.p`
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 0.25rem;
`;

const Description = styled(Text).attrs(() => ({ type: 'body2' }))`
    font-size: 0.875rem;
    line-height: 1.3;
`;

export { Container, Content, Title, Description };
