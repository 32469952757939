import styled, { createGlobalStyle } from 'styled-components';

const CustomGlobalStyles = createGlobalStyle`
    html {
        font-family: 'Gotham SSm A', 'Gotham SSm B', arial, sans-serif !important;
        scroll-behavior: smooth;
    }

    html, body, #root {
        width: auto;
        height: auto;
        overflow: unset;
    }

    body {
        color: #4a4a4a
    }
`;

const MainContainer = styled.section`
    position: relative;
    width: auto;
`;

const WidgetContainer = styled.section`
    position: relative;
    display: flex;
    justify-content: center;
    width: auto;
    min-height: 25rem;
`;

export { CustomGlobalStyles, MainContainer, WidgetContainer };
