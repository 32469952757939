import { createRoot } from 'react-dom/client';

import { installPolyFills } from '../polyfills';
import { providerWidgetSchedulerConfigSchema, locationWidgetSchedulerConfigSchema } from 'schemas/app-config';
import { ErrorBoundaryFallback } from 'components/error-boundary/fallback';
import { WidgetAppOptions } from 'types';
import { initCountly } from 'modules/countly';
import { datadogRum, initDatadog } from 'modules/datadog';
import { appConfig } from 'modules/app-config';
import { WidgetScheduler } from 'roots/widget';
import { ThemeProvider } from 'theme/theme-provider';
import { initGoogleTagManager } from 'modules/google-tag-manager';
import { setUserTypeFromUrlToStorage } from 'utils/url';
import { getWidgetBookingParamsFromUrl } from 'widget/utils/url';
import { AppOptionsContextProvider } from 'contexts/app-options';
import { isWidgetSchedulingEnabled } from 'widget/utils/scheduling';
import { setRwgTokenFromUrlParamsToCookie } from 'hooks/use-submit-google-conversion-token';

const render = async (options: WidgetAppOptions) => {
    setRwgTokenFromUrlParamsToCookie();
    const root = createRoot(document.getElementById(options.rootElementId)!);

    try {
        // Installing necessary polyfills should always be the first to execute
        await installPolyFills();

        initCountly();
        initDatadog(appConfig.datadog, options);
        initGoogleTagManager();
        setUserTypeFromUrlToStorage();

        const extendedOptions: WidgetAppOptions = {
            ...options,
            ...getWidgetBookingParamsFromUrl(),
            enabledForScheduling: isWidgetSchedulingEnabled(options),
            practice: 'villagefamilypractice',
        };

        if (extendedOptions.type === 'locationWidget') {
            const validatedOptions = locationWidgetSchedulerConfigSchema.validateSync(extendedOptions, {
                strict: true,
                stripUnknown: true,
            });

            root.render(<WidgetScheduler options={validatedOptions} />);
        } else if (extendedOptions.type === 'providerWidget') {
            const validatedOptions = providerWidgetSchedulerConfigSchema.validateSync(extendedOptions, {
                strict: true,
                stripUnknown: true,
            });

            root.render(<WidgetScheduler options={validatedOptions} />);
        } else {
            throw new Error(`Unknown widgetType: ${extendedOptions['type']}`);
        }
    } catch (error) {
        datadogRum.addError(error, {
            level: 'fatal',
            extra: { options: JSON.stringify(options) },
        });

        root.render(
            <ThemeProvider>
                <AppOptionsContextProvider options={options}>
                    <ErrorBoundaryFallback
                        error={error as Error}
                        resetErrorBoundary={() => {
                            root.unmount();
                            render(options);
                        }}
                    />
                </AppOptionsContextProvider>
            </ThemeProvider>
        );
    }
};

const { version } = appConfig;
console.info(`Scheduler version: ${version} - date: ${__RELEASE_DATE__}`);

export { render, version };
