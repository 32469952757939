import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react';

import { useAppOptions, useBooking, useIsMobile } from 'hooks';
import { Countly } from 'modules/countly';
import { CaptureCountlyEventsContext } from './capture-countly-events-context';

const CaptureCountlyEventsContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const { booking, getBooking } = useBooking();
    const appOptions = useAppOptions();
    const isPageLoadEventSent = useRef(false);
    const isMobile = useIsMobile();

    const addCountlyEvent: typeof Countly.addEvent = useCallback(
        ({ key, segmentation }) => {
            const { market, isExistingPatient, department, reason } = getBooking();

            Countly.addEvent({
                key,
                segmentation: {
                    departmentId: appOptions.type === 'citymd' ? appOptions.departmentId : department?.departmentid,
                    departmentName: appOptions.type === 'citymd' ? appOptions.departmentName : department?.name,
                    firstTimePatient: isExistingPatient === undefined ? undefined : isExistingPatient ? 'No' : 'Yes',
                    isInPerson: reason?.is_in_person === undefined ? undefined : reason.is_in_person ? 'Yes' : 'No',
                    market: market?.market_key,
                    page: appOptions.type === 'citymd' ? appOptions.page : undefined,
                    reasonId: reason?.id,
                    reasonName: reason?.name,
                    schedulerType: appOptions.type,
                    viewportType: isMobile ? 'Mobile' : 'Desktop',
                    ...segmentation,
                },
            });
        },
        [getBooking, appOptions, isMobile]
    );

    useEffect(() => {
        // Send pageLoad event on main scheduler load only
        if (!isPageLoadEventSent.current && appOptions.type === 'main') {
            addCountlyEvent({ key: 'pageLoad' });
            isPageLoadEventSent.current = true;
        }
    }, [appOptions.type, addCountlyEvent]);

    useEffect(() => {
        // Send pageLoad event on citymd scheduler load only
        if (!isPageLoadEventSent.current && appOptions.type === 'citymd') {
            addCountlyEvent({ key: 'pageLoad', segmentation: { page: appOptions.page } });
            isPageLoadEventSent.current = true;
        }
    }, [appOptions.type, addCountlyEvent, appOptions]);

    useEffect(() => {
        // Send pageLoad event on widget load only if market, department and reason are selected
        if (
            !isPageLoadEventSent.current &&
            booking.market &&
            booking.department &&
            booking.reason &&
            appOptions.type !== 'main'
        ) {
            addCountlyEvent({ key: 'pageLoad' });
            isPageLoadEventSent.current = true;
        }
    }, [booking.market, booking.department, booking.reason, appOptions.type, addCountlyEvent, isPageLoadEventSent]);

    const contextValue = useMemo(() => ({ addCountlyEvent }), [addCountlyEvent]);

    return <CaptureCountlyEventsContext.Provider value={contextValue}>{children}</CaptureCountlyEventsContext.Provider>;
};

export { CaptureCountlyEventsContextProvider };
