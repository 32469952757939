const SLOTS_PER_COLUMN = 4;
const WIDGET_SLOTS_PER_COLUMN = 3;

const IN_CLINIC_MIN_SCHEDULING_HOURS = 24;
const IN_CLINIC_MAX_SCHEDULING_HOURS = 90 * 24; //90 days

const NEXT_AVAILABLE_APPOINTMENTS_QUERIES_BATCH_SIZE = 3;

export {
    SLOTS_PER_COLUMN,
    WIDGET_SLOTS_PER_COLUMN,
    IN_CLINIC_MIN_SCHEDULING_HOURS,
    IN_CLINIC_MAX_SCHEDULING_HOURS,
    NEXT_AVAILABLE_APPOINTMENTS_QUERIES_BATCH_SIZE,
};
