import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ComponentType, FC, PropsWithChildren } from 'react';

import { ErrorBoundaryFallback } from 'components/error-boundary/fallback';

interface SchedulerErrorBoundaryProps {
    resetKeys?: string[];
    fallbackComponent?: ComponentType<FallbackProps>;
}

const SchedulerErrorBoundary: FC<PropsWithChildren & SchedulerErrorBoundaryProps> = ({
    children,
    resetKeys,
    fallbackComponent = ErrorBoundaryFallback,
}) => {
    const { reset } = useQueryErrorResetBoundary();

    return (
        <ErrorBoundary FallbackComponent={fallbackComponent} onReset={reset} resetKeys={resetKeys}>
            {children}
        </ErrorBoundary>
    );
};

export { SchedulerErrorBoundary };
