import { Autocomplete, AutocompleteOption, AutocompleteProps } from '@village/ui';
import { prop, sortBy } from 'ramda';
import { useMemo, useCallback, forwardRef } from 'react';

import { Market } from 'types';
import { useBooking } from 'hooks';

interface MarketOption extends AutocompleteOption {
    marketId: number;
}

interface Props extends Omit<AutocompleteProps<MarketOption, false>, 'onChange' | 'options' | 'value'> {
    label?: string;
    markets: Market[] | undefined;
    onChange: (market: Market | undefined) => void;
}

const MarketField = forwardRef<HTMLDivElement, Props>(({ label, id, onChange, markets, ...props }, ref) => {
    const { booking } = useBooking();

    const options = useMemo(() => {
        const unsortedOptions =
            markets?.map<MarketOption>((market) => ({
                marketId: market.id,
                label: market.display_name,
                value: market.id.toString(),
            })) ?? [];

        return sortBy(prop('label'), unsortedOptions);
    }, [markets]);

    const handleOnChange = useCallback(
        (option: MarketOption | null) => {
            const market = markets?.find((marketItem) => option?.marketId === marketItem.id);
            onChange(market);
        },
        [markets, onChange]
    );

    const selectedOption = options.find((option) => option.marketId === booking.market?.id);

    return (
        <div ref={ref}>
            <Autocomplete
                id={id}
                label={label}
                aria-label={label}
                blurInputOnSelect={true}
                options={options}
                onChange={handleOnChange}
                value={selectedOption ?? null}
                noOptionsMessage={() => 'No markets available'}
                {...props}
            />
        </div>
    );
});

export { MarketField };
