import { ReplayIconDeprecated, BandAidIconDeprecated } from '@village/icons';
import { Button } from '@village/ui';
import { FC } from 'react';
import { AxiosError } from 'axios';

import { useErrorBoundaryMonitoring } from 'hooks';
import * as Styled from './styles';

interface Props {
    resetErrorBoundary: () => void;
    error: AxiosError | Error;
}

const ErrorBoundaryFallback: FC<Props> = ({ resetErrorBoundary, error }) => {
    useErrorBoundaryMonitoring(error);

    return (
        <Styled.ErrorBoundaryContainer data-testid="error-boundary-screen">
            <Styled.ErrorBoundaryWrapper>
                <BandAidIconDeprecated size={4.5} />
                <Styled.ErrorMessage>
                    <span>Oops!</span>
                    <span>Something went wrong</span>
                </Styled.ErrorMessage>
                <Styled.Hint>We're working on fixing the issue. If the issue persists, please call us.</Styled.Hint>
                <Button startIcon={<ReplayIconDeprecated />} onClick={resetErrorBoundary} fullWidth={true}>
                    Retry
                </Button>
            </Styled.ErrorBoundaryWrapper>
        </Styled.ErrorBoundaryContainer>
    );
};

export { ErrorBoundaryFallback };
