import { CalendarIcon } from '@village/icons';
import { Button, ButtonProps } from '@village/ui';
import { formatRelative, Locale } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { forwardRef, Fragment } from 'react';

import { useBooking, useIsMobile } from 'hooks';
import { formatDate } from 'utils/date';
import * as Styled from './styles';

interface Props extends ButtonProps {
    endDate: Date;
}

const locale: Locale = {
    ...enUS,
    formatRelative: (token) => {
        if (token === 'today') {
            return "'Today'";
        }

        if (token === 'tomorrow') {
            return "'Tomorrow'";
        }

        return 'EEE';
    },
};

const DatePickerCustomInput = forwardRef<HTMLButtonElement, Props>(({ endDate, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { booking } = useBooking();
    const { date, department, reason } = booking;

    return (
        <Button
            ref={ref}
            {...props}
            disabled={!department || !reason}
            variant="tertiary"
            size="medium"
            startIcon={<CalendarIcon />}
        >
            {isMobile ? (
                <Fragment>
                    <Styled.Day>{formatDate(date, 'shortMonthDay')}</Styled.Day>&nbsp;-&nbsp;
                    <Styled.Day>{formatDate(endDate, 'shortMonthDay')}</Styled.Day>
                </Fragment>
            ) : (
                <Fragment>
                    <Styled.Day>{formatRelative(date, new Date(), { locale })},&nbsp;</Styled.Day>
                    <Styled.Day>{formatDate(date, 'shortMonthDay')}</Styled.Day>&nbsp;-&nbsp;
                    <Styled.Day>{formatRelative(endDate, new Date(), { locale })},&nbsp;</Styled.Day>
                    <Styled.Day>{formatDate(endDate, 'shortMonthDay')}</Styled.Day>
                </Fragment>
            )}
        </Button>
    );
});

export { DatePickerCustomInput };
