import { useAppOptions } from './use-app-options';
import { useIsMobile } from './use-is-mobile';

const useAppointmentsRange = () => {
    const appOptions = useAppOptions();
    const isMobile = useIsMobile();

    if (appOptions.type === 'locationWidget' || appOptions.type === 'providerWidget') {
        return { datesPadding: 0, datesRange: isMobile ? 2 : 4 };
    }

    return { datesPadding: isMobile ? 1 : 0, datesRange: isMobile ? 1 : 5 };
};

export { useAppointmentsRange };
