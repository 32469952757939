import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { DefaultOptions, QueryClient } from '@tanstack/react-query';
import { PersistedClient, persistQueryClient } from '@tanstack/react-query-persist-client';
import { AxiosError } from 'axios';
import { mergeDeepRight, isEmpty, path } from 'ramda';

import { storage } from 'utils/storage';
import { appConfig } from './app-config';

const reactQueryDefaultOptions: DefaultOptions<Error> = {
    queries: {
        refetchOnWindowFocus: false,
        /**
         * Mark axios errors with `__ignoreErrorBoundary` to prevent them from triggering the error screen
         * For example: When a request is aborted by the browser (User navigated away)
         */
        throwOnError: (error) => path(['__ignoreErrorBoundary'], error) !== true,
        staleTime: Infinity,
        /**
         * A retry is a mechanism that monitors a request,
         * and on the detection of failure automatically fires a repeat of the request.
         * A retry of a request should only be considered when there is a chance of success,
         * for example in response to error codes 500 (Internal Server Error) and 503 (Service Unavailable).
         * Attempting to retry on any and every error is inadvisable
         * as it wastes resources and can produce unexpected results.
         * Read more:
         * - https://denalibalser.medium.com/best-practices-for-retry-685bf58de797
         * - https://medium.com/capital-one-tech/3-best-practices-for-api-clients-feef9ec97f1a
         */
        retry: (failureCount, error) => {
            const axiosError = error as AxiosError | undefined;
            const errorStatus = axiosError?.response?.status;
            return failureCount < 2 && !!errorStatus && (errorStatus === 429 || errorStatus >= 500);
        },
    },
};

const queryClient = new QueryClient({ defaultOptions: reactQueryDefaultOptions });

const queriesToCache = ['hubspot-providers'];

const localStoragePersister = createSyncStoragePersister({
    storage,
    serialize: (data: PersistedClient) =>
        JSON.stringify(
            mergeDeepRight(data, {
                clientState: {
                    queries: data.clientState.queries.filter(({ queryKey, state }) => {
                        return (
                            queriesToCache.includes(queryKey[0] as string) &&
                            // Do not save empty responses in the cache
                            // `isEmpty` Returns true if the given value is its type's empty value; false otherwise.
                            !isEmpty(state.data)
                        );
                    }),
                },
            })
        ),
});

persistQueryClient({
    buster: appConfig.version,
    queryClient,
    persister: localStoragePersister,
    maxAge: 1000 * 60 * 60 * 24, // 24 hours
});

export { queryClient, reactQueryDefaultOptions };
