const VILLAGE_MEDICAL_BASE_URL = 'https://www.villagemedical.com/';

const LINKS = {
    symptoms: 'https://www.cdc.gov/coronavirus/2019-nCoV/index.html',
    villageMedical: {
        covid19: `${VILLAGE_MEDICAL_BASE_URL}covid19info`,
        locator: `${VILLAGE_MEDICAL_BASE_URL}locator`,
        logo: `${VILLAGE_MEDICAL_BASE_URL}hubfs/villagemedical/VMedical%20%20Logo_RGB_Color_horizontal.png`,
        logoFooter: `${VILLAGE_MEDICAL_BASE_URL}hs-fs/hubfs/village-medical-white-transparent.png?width=166&height=30&name=village-medical-white-transparent.png`,
        appleStoreUrl: 'https://apps.apple.com/us/app/village-medical/id1514618127',
        googlePlayStoreUrl: 'https://play.google.com/store/apps/details?id=com.villagemd.patx',
        privacy: `${VILLAGE_MEDICAL_BASE_URL}privacy-policy`,
        privacyPractices: `${VILLAGE_MEDICAL_BASE_URL}notice-privacy-practices`,
        providerBase: `${VILLAGE_MEDICAL_BASE_URL}our-providers`,
        terms: `${VILLAGE_MEDICAL_BASE_URL}terms-and-conditions`,
        virtualInformedConsent: `${VILLAGE_MEDICAL_BASE_URL}virtual-informed-consent`,
        virtualVisits: `${VILLAGE_MEDICAL_BASE_URL}virtual-visits`,
    },
};

export { LINKS };
