import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';

import { hubspotApi } from 'api/hubspot';
import { appConfig } from 'modules/app-config';
import type { HubspotResponse, HubspotProvider, Booking, AceProvider } from 'types';
import { hubspotProviderFactory } from 'utils/provider';

const useHubspotProviders = (booking: Booking, aceProviders: AceProvider[]): UseQueryResult<HubspotProvider[]> => {
    const { departmentAndRelatedDepartments } = booking;
    const allProviderLists = departmentAndRelatedDepartments.flatMap(({ providerlist }) => providerlist);

    const providersNpis = aceProviders
        .filter(({ npi, providerid }) => npi && allProviderLists.includes(Number(providerid)))
        .map(({ npi }) => npi)
        .filter((npi): npi is number => npi != null);

    return useQuery({
        queryKey: ['hubspot-providers', providersNpis],
        queryFn: ({ signal }) => {
            const queries = providersNpis.map((providerNpi) =>
                hubspotApi.get<HubspotResponse>(
                    `tables/${appConfig.hubspotProvidersTableId}/rows?portalId=${appConfig.hubspotPortalId}`,
                    {
                        params: {
                            npi__eq: providerNpi,
                        },
                        signal,
                    }
                )
            );

            return Promise.all(queries)
                .then((responses) =>
                    responses
                        .flatMap(({ data }) => data.results)
                        .map((hubspotObjectValue) => hubspotProviderFactory(hubspotObjectValue))
                )
                .catch(() => {
                    // Query error is already captured by sentry in the axios interoceptor.
                    return [];
                });
        },
        staleTime: Infinity,
        enabled: Boolean(providersNpis.length),
        throwOnError: false,
    });
};

export { useHubspotProviders };
