import { FC, PropsWithChildren, useCallback, useMemo, useState, useRef } from 'react';
import { concat, equals } from 'ramda';
import { isPast } from 'date-fns';

import { useProviders, useDepartmentById, useAppOptions } from 'hooks';
import type { Booking, Provider } from 'types';
import { getRelatedDepartments } from 'utils/department';
import { BookingContext, BookingContextType } from './booking-context';

interface Props {
    bookingInitial?: Partial<Booking>;
}

const LocationWidgetBookingContextProvider: FC<PropsWithChildren<Props>> = ({ children, bookingInitial }) => {
    const [booking, setBooking] = useState<Booking>({
        appointment: undefined,
        date: new Date(),
        department: undefined,
        market: undefined,
        provider: undefined,
        providersAndCareTeams: [],
        departmentAndRelatedDepartments: [],
        reason: undefined,
        isExistingPatient: undefined,
        patientInformation: undefined,
        reasonDetails: '',
        previouslySelectedReason: undefined,
        patientIdentification: undefined,
        ...bookingInitial,
    });

    const { data: providers } = useProviders(booking);
    const widgetOptions = useAppOptions('locationWidget');
    const { data: departments } = useDepartmentById(booking, widgetOptions.departmentId);

    // Because booking state is rendered asynchronously, we save the new booking object in a ref,
    // which is consumed by the provider and reflected immediately for usage in components
    const latestBookingState = useRef(booking);

    const setBookingField: BookingContextType['setBookingField'] = useCallback(
        (partialBooking) => {
            setBooking((state) => {
                const newState = { ...state, ...partialBooking };

                if (equals(newState, state)) {
                    return state;
                }

                const relatedDepartments = getRelatedDepartments(newState.department, newState.market, departments);
                latestBookingState.current = {
                    ...newState,
                    // if the date is in the past, set it to today
                    date: isPast(newState.date) ? new Date() : newState.date,
                    // Combine selected department and related departments
                    departmentAndRelatedDepartments: concat(newState.department ? [newState.department] : [], relatedDepartments),
                };

                return latestBookingState.current;
            });
        },
        [departments]
    );

    const getBooking: BookingContextType['getBooking'] = useCallback((): Booking => latestBookingState.current, []);

    const bookingContextValue = useMemo(
        () => ({
            booking: {
                ...booking,
                providersAndCareTeams: providers ?? ([] as Provider[]),
            },
            setBookingField,
            getBooking,
        }),
        [booking, providers, setBookingField, getBooking]
    );

    return <BookingContext.Provider value={bookingContextValue}>{children}</BookingContext.Provider>;
};

export { LocationWidgetBookingContextProvider };
