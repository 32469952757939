import { useMemo } from 'react';

import type { Booking } from 'types';
import { useAceDepartments } from './use-ace-departments';
import { useHubspotLocations } from './use-hubspot-locations';
import { departmentFactory } from 'utils/department';

/**
 * @returns departments: Ace departments enhanced with HubDB locations details
 */
const useDepartments = (booking: Booking) => {
    const {
        data: aceDepartments,
        isFetching: aceDepartmentsFetching,
        isFetched: aceDepartmentsFetched,
    } = useAceDepartments(booking);

    const {
        data: hubspotLocations,
        isFetching: hubspotLocationsFetching,
        isFetched: hubspotLocationsFetched,
    } = useHubspotLocations(booking);

    // Enhance Ace departments with the hubDB locations details.
    const enhancedDepartments = useMemo(() => {
        // To avoid too many renders, wait for hubspot locations
        // to load before returning the enhanced departments
        if (!hubspotLocations || !aceDepartments) {
            return undefined;
        }

        const locationsMap = new Map(hubspotLocations?.map((location) => [location.departmentId, location]));

        return aceDepartments?.map((aceDepartment) => {
            const hubspotLocation = locationsMap.get(aceDepartment.departmentid);
            return departmentFactory(aceDepartment, hubspotLocation);
        });
    }, [aceDepartments, hubspotLocations]);

    return {
        data: enhancedDepartments,
        isFetching: aceDepartmentsFetching || hubspotLocationsFetching,
        // If ace response was empty we do not trigger hubDB request to load locations
        isFetched: aceDepartmentsFetched && (hubspotLocationsFetched || !hubspotLocationsFetching),
    };
};

export { useDepartments };
