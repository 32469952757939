import { ICCalendarIcon, PhoneOutlinedIcon } from '@village/icons';
import { FC, Fragment, useEffect, useMemo } from 'react';
import { Button } from '@village/ui';

import { SchedulingUnavailableReason, useAppOptions, useCaptureCountlyEvent } from 'hooks';
import { DepartmentHubspotInfo } from 'types';
import * as Styled from './styles';
import { formatPhoneWithNoDash } from 'utils/phone-number';

interface Props {
    reason: SchedulingUnavailableReason;
    location?: Partial<DepartmentHubspotInfo> | null;
    displayIcon?: boolean;
    disabledFor?: 'provider' | 'location';
}

const SchedulingNotAvailable: FC<Props> = ({ location, reason, disabledFor, displayIcon }) => {
    const appOptions = useAppOptions();
    const { addCountlyEvent } = useCaptureCountlyEvent();

    const locationNotSchedulingNewPatients = appOptions.type === 'locationWidget' && reason === 'NO_REASONS_NEW_PATIENTS';
    const providerNotSchedulingNewPatients = appOptions.type === 'providerWidget' && reason === 'NO_REASONS_NEW_PATIENTS';
    const phoneNumber = appOptions.type === 'locationWidget' ? appOptions?.phone : location?.phone;

    const { title, subTitle, cta } = useMemo(() => {
        const handleClickToCall = () => {
            addCountlyEvent({ key: 'clickToCall' });
        };

        if (locationNotSchedulingNewPatients) {
            return {
                title: 'No providers at this location are scheduling new patients.',
                subTitle: (
                    <Fragment>
                        To find availability for providers at another clinic close to you, <a href="/locator">click here</a>
                    </Fragment>
                ),
            };
        }

        if (providerNotSchedulingNewPatients) {
            return {
                title: 'This provider is not scheduling new patients.',
                subTitle: location ? (
                    <Fragment>
                        To view availability for other providers at this same location,{' '}
                        <a href={`/locations${location.path}`}>click here</a>
                    </Fragment>
                ) : (
                    'To schedule an appointment, please call the clinic phone number.'
                ),
            };
        }

        if ((appOptions.type === 'locationWidget' || appOptions.type === 'main') && disabledFor === 'provider') {
            return {
                title: null,
                subTitle: "Online scheduling isn't available for this provider. Please call to book.",
                cta: phoneNumber && (
                    <Styled.ButtonWrapper appType={appOptions.type}>
                        <Button
                            startIcon={<PhoneOutlinedIcon />}
                            variant="secondary"
                            size="medium"
                            fullWidth={true}
                            onClick={() => handleClickToCall()}
                            data-testid="scheduling-not-available-phone"
                        >
                            <Styled.Link href={`tel:${formatPhoneWithNoDash(phoneNumber)}`} />
                            {phoneNumber}
                        </Button>
                    </Styled.ButtonWrapper>
                ),
            };
        }

        return {
            title: 'Online scheduling is not currently available at this location',
            subTitle: "We're here to help. Please call to book.",
            cta: phoneNumber && (
                <Styled.ButtonWrapper appType={appOptions.type}>
                    <Button
                        startIcon={<PhoneOutlinedIcon />}
                        variant="secondary"
                        size="medium"
                        fullWidth={true}
                        onClick={() => handleClickToCall()}
                        data-testid="scheduling-not-available-phone"
                    >
                        <Styled.Link href={`tel:${formatPhoneWithNoDash(phoneNumber)}`} />
                        {phoneNumber}
                    </Button>
                </Styled.ButtonWrapper>
            ),
        };
    }, [
        locationNotSchedulingNewPatients,
        providerNotSchedulingNewPatients,
        appOptions.type,
        disabledFor,
        phoneNumber,
        addCountlyEvent,
        location,
    ]);

    useEffect(() => {
        addCountlyEvent({
            key: 'schedulingNotAvailable',
            segmentation: {
                reason,
                providerId: appOptions.type === 'providerWidget' ? appOptions.providerNpi : undefined,
                departmentId: appOptions.type === 'locationWidget' ? appOptions.departmentId : undefined,
            },
        });
    }, [reason, appOptions, addCountlyEvent]);

    return (
        <Styled.Container>
            {displayIcon && (
                <Styled.Icon>
                    <ICCalendarIcon />
                </Styled.Icon>
            )}
            {title ? <Styled.Title>{title}</Styled.Title> : null}
            <Styled.SubTitle>{subTitle}</Styled.SubTitle>
            {cta ?? null}
        </Styled.Container>
    );
};

export { SchedulingNotAvailable };
