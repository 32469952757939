import { differenceInYears, format, isToday, isTomorrow, parse } from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';
import { format as formatTz, toZonedTime } from 'date-fns-tz';

const dateFormats = {
    iso: 'yyyy-MM-dd',
    dayOnly: 'EEE',
    shortDay: 'MMMM d',
    shorterDay: 'MMM. d',
    fullDate: 'MMM d, yyyy',
    monthDay: 'EEEE, MMMM d',
    monthDayShort: 'EEE, MMM d',
    monthDayYear: 'MM/dd/yyyy',
    monthDayYearWithTime: 'MM/dd/yyyy HH:mm:ss',
    shortMonthDay: 'MMM d',
    timeWithTimezone: 'p (O)',
    dateWithTimezone: 'EEE, MMMM d, h:mm aa zzz',
    dateWithTimezoneParenthesis: 'EEEE, MMMM d, h:mm aa (zzz)',
    shortMonthDateWithTimezone: 'EEE, MMM d, h:mm aa zzz',
    shortMonthDateWithTimezoneParenthesis: 'EEEE, MMM d, h:mm aa (zzz)',
    timeTwelveHour: 'h:mm aaa',
    timeTwentyFourHour: 'HH:mm',
    timezone: 'zzz',
    appointmentDate: "h:mm aaa 'on' EEEE, MMM do yyyy",
    appointmentDateAlternativeShort: "MMM. d '-' h:mm aaa",
    appointmentDateAlternativeLong: "MMMM d '-' h:mm aaa",
};

type DateFormatName = keyof typeof dateFormats;

const formatDate = (date: Date | number | string, formatName: DateFormatName): string =>
    format(new Date(date), dateFormats[formatName], { locale: enUS });

const isLegalAge = (dateOfBirth: string) =>
    differenceInYears(new Date(), parse(dateOfBirth, dateFormats.monthDayYear, new Date())) >= 18;

const getDepartmentTimezone = (timezoneName: string): { full: string; short: string } => {
    const zoneString = formatTz(toZonedTime(new Date(), timezoneName), 'zzz', {
        timeZone: timezoneName,
        locale: enUS,
    });

    return {
        full: timezoneName.replace(/_/, ' '),
        short: zoneString,
    };
};

const getRelativeDayOfTheWeek = (date: Date, dayFormat: string = dateFormats.dayOnly) => {
    if (isToday(date)) return 'Today';
    if (isTomorrow(date)) return 'Tomorrow';

    // If not today or tomorrow, returns the short day of the week
    // i.e. "Mon" || "Tue" || "Wed" etc.
    return format(date, dayFormat);
};

export { formatDate, isLegalAge, getDepartmentTimezone, getRelativeDayOfTheWeek, dateFormats };
export type { DateFormatName };
