import { Autocomplete, AutocompleteOption, AutocompleteProps } from '@village/ui';
import { prop, sortBy, uniqBy } from 'ramda';
import { useId, useMemo, useCallback, useEffect, ReactElement, forwardRef } from 'react';

import { useBooking } from 'hooks';
import { getAllowedProviders } from 'utils/provider';
import { Provider } from 'types';

interface ProviderOption extends AutocompleteOption {
    providerId: number;
}

interface Props extends Omit<AutocompleteProps<ProviderOption, false>, 'onChange' | 'options' | 'value'> {
    label?: string;
    providers: Provider[] | undefined;
}

const ProviderField = forwardRef<HTMLDivElement, Props>(({ label, id, providers, ...props }, ref): ReactElement => {
    const localId = useId();
    const { setBookingField, booking } = useBooking();

    const { departmentAndRelatedDepartments, reason } = booking;

    const options = useMemo(() => {
        if (!providers || providers.length === 0 || !reason) {
            return [];
        }

        // Reason-department-provider mapping.
        // return providers that take the selected reason at selected departments.
        const allowedProvidersByDepartment = getAllowedProviders(departmentAndRelatedDepartments, reason, providers);

        const allowedProvides = allowedProvidersByDepartment.flatMap(([, providersAllowed]) => providersAllowed);

        const uniqAllowedProvides = uniqBy(({ providerid }) => providerid, allowedProvides);

        const unsortedOptions = uniqAllowedProvides.map<ProviderOption>((provider) => ({
            value: provider.providerid.toString(),
            label: provider.displayname,
            providerId: provider.providerid,
        }));

        return sortBy(prop('label'), unsortedOptions);
    }, [reason, providers, departmentAndRelatedDepartments]);

    const handleOnChange = useCallback(
        (option: ProviderOption | null) => {
            const provider = providers?.find(({ providerid }) => option?.providerId === providerid);
            setBookingField({ provider });
        },
        [providers, setBookingField]
    );

    const selectedOption = options.find((option) => option.providerId === booking.provider?.providerid);

    useEffect(() => {
        if (!selectedOption) {
            setBookingField({ provider: undefined });
        }
    }, [selectedOption, setBookingField]);

    return (
        <div ref={ref}>
            <Autocomplete
                id={id ?? localId}
                aria-label={label}
                blurInputOnSelect={true}
                label={label}
                isDisabled={!reason}
                options={options}
                onChange={handleOnChange}
                value={selectedOption ?? null}
                noOptionsMessage={() => 'No providers'}
                placeholder="Select provider"
                isClearable={true}
                {...props}
            />
        </div>
    );
});

export { ProviderField };
