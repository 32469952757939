import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Label = styled(Text).attrs(() => ({ type: 'caption1', tag: 'label' }))`
    display: block;
    margin-bottom: ${rem(6)};
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

export { Label };
