import { Radio, Text } from '@village/ui';
import styled from 'styled-components';

const Label = styled(Text).attrs(() => ({ type: 'caption1', tag: 'label' }))`
    display: inline-block;
    margin-bottom: 0.375rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const ValuesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    border-radius: 4px;
`;

const RadioInput = styled(Radio)`
    height: 3.5rem;
    padding: 0 0.75rem;

    &:last-of-type {
        border-left: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    }
`;

export { Label, ValuesContainer, RadioInput };
