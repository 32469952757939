import { differenceInHours } from 'date-fns';
import { fromPairs, keys } from 'ramda';

import { UTM_STORAGE_KEY__VMD, UTM_STORAGE_KEY__CMD } from 'constants/storage';
import { bookingParamsSchema } from 'schemas/app-config';
import { BookingParamsSchema } from 'types';
import { storage } from 'utils/storage';

interface UtmParams {
    utmTerm: string | null;
    utmCampaign: string | null;
    utmSource: string | null;
    utmContent: string | null;
    utmMedium: string | null;
}

const pendingBookingParamsToSync = new Set<keyof BookingParamsSchema>(keys<Partial<BookingParamsSchema>>({}));

const getBookingParamsFromUrl = (): Partial<BookingParamsSchema> | undefined => {
    try {
        // we are using the `location.hash` because we use `HashRouter` from react-router
        const [_pathname, params] = window.location.hash.split('?');
        const pairs = Array.from(new URLSearchParams(params).entries());
        return bookingParamsSchema.validateSync(fromPairs(pairs));
    } catch {
        return undefined;
    }
};

const getUtmParams = (storageKey: typeof UTM_STORAGE_KEY__VMD | typeof UTM_STORAGE_KEY__CMD): UtmParams | null => {
    try {
        const paramsString = window.sessionStorage.getItem(storageKey);
        if (!paramsString) {
            return null;
        }

        const params = JSON.parse(paramsString) as {
            timestamp: number;
            utm_term: string | null;
            utm_campaign: string | null;
            utm_source: string | null;
            utm_content: string | null;
            utm_medium: string | null;
        };

        if (!params || typeof params !== 'object' || typeof params?.timestamp !== 'number') {
            window.sessionStorage.removeItem(storageKey);
            return null;
        }

        // When UTM params are more than 24 hours old, remove them.
        if (differenceInHours(Date.now(), params.timestamp) >= 24) {
            window.sessionStorage.removeItem(storageKey);
            return null;
        }

        return {
            utmTerm: params.utm_term ?? null,
            utmCampaign: params.utm_campaign ?? null,
            utmSource: params.utm_source ?? null,
            utmContent: params.utm_content ?? null,
            utmMedium: params.utm_medium ?? null,
        };
    } catch {
        window.sessionStorage.removeItem(storageKey);
        return null;
    }
};

/**
 * Adds ability to get userType from URL for call center reps only at this time
 * Sets in local storage for later use in Countly
 */
const setUserTypeFromUrlToStorage = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const userType = searchParams.get('userType');
    if (userType) {
        searchParams.delete('userType');
        storage.setItem('USER_TYPE', userType);
    }
};

export { getBookingParamsFromUrl, getUtmParams, setUserTypeFromUrlToStorage, pendingBookingParamsToSync };
