import { useEffect, useMemo, useState } from 'react';

import { Countly } from 'modules/countly';
import { CountlyRemoteConfigPayload, RemoteConfig } from 'types';
import { datadogRum } from 'modules/datadog';

const useRemoteConfig = (): RemoteConfig => {
    const [remoteConfigValues, setRemoteConfigValues] = useState<CountlyRemoteConfigPayload | undefined>(undefined);

    useEffect(() => {
        Countly.fetchRemoteConfig()
            .then((remoteConfigs) => setRemoteConfigValues(remoteConfigs))
            .catch((error) => {
                console.error(error);
                datadogRum.addError(error ?? 'Failed to load countly remote config');
            });
    }, []);

    return useMemo<RemoteConfig>(
        () => ({
            nextAvailableDateRange: remoteConfigValues?.feature_public_scheduler_next_available_date_range ?? 30,
            nextAvailableDateRangeSecondary:
                remoteConfigValues?.feature_public_scheduler_next_available_date_range_secondary ?? 30,
            prnVisibleDateRange: remoteConfigValues?.feature_public_scheduler_prn_visible_date_range ?? 30,
        }),
        [remoteConfigValues]
    );
};

export { useRemoteConfig };
