import { GroupIcon } from '@village/icons';
import React from 'react';

import { Provider } from 'types';
import * as Styled from './styles';

interface Props {
    provider: Provider;
}

const CareTeamHeader: React.FC<Props> = ({ provider }) => (
    <Styled.Container data-testid="care-team-header">
        <GroupIcon size="lg" />
        <Styled.Content>
            <Styled.Title>{provider.displayname}'s team</Styled.Title>
            <Styled.Description>
                The providers below work closely with {provider.displayname} and might have more availability.
            </Styled.Description>
        </Styled.Content>
    </Styled.Container>
);

export { CareTeamHeader };
