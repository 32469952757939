import { useMutation } from '@tanstack/react-query';

import { googleMapsConversionApi } from 'api/google-maps-conversion';
import { appConfig } from 'modules/app-config';
import { storage } from 'utils/storage';

const MERCHANT_ID_STORAGE_KEY = 'GMB_MERCHANT_CHANGED';

const useSubmitGoogleConversionToken = () => {
    const { mutateAsync } = useMutation({
        mutationKey: ['google-conversion-token'],
        mutationFn: async ({
            conversion_partner_id,
            rwg_token,
            merchant_changed,
        }: {
            conversion_partner_id: string;
            rwg_token: string;
            merchant_changed: string;
        }) => {
            try {
                const response = await googleMapsConversionApi.post<unknown>(
                    `/collect`,
                    {
                        conversion_partner_id,
                        rwg_token,
                        merchant_changed,
                    },
                    {
                        headers: {
                            'Content-Type': 'text/plain',
                        },
                    }
                );

                return response;
            } catch {
                // Do nothing, error is already reported to datadog.
                // If the request fails, we don't want to block the user from booking an appointment.
            }
        },
    });

    const submitGoogleConversionToken = async (): Promise<unknown> => {
        const rwgToken = getRwgToken();
        const merchantChanged = storage.getItem(MERCHANT_ID_STORAGE_KEY);

        if (rwgToken) {
            return mutateAsync({
                conversion_partner_id: appConfig.gmb.partnerId.toString(),
                rwg_token: rwgToken,
                /**
                 * [1]. This value should be used when a user has left the original merchant's website
                 *      and completed a purchase through your platform with a different merchant
                 * [2]. This value should be used when the customer completed a transaction through the original Entity (Merchant).
                 */
                merchant_changed: merchantChanged || '2',
            });
        }
    };

    return { submitGoogleConversionToken };
};

function setRwgTokenFromUrlParamsToCookie() {
    const searchParams = new URLSearchParams(window.location.search);
    const rwgToken = searchParams.get('rwg_token');
    const merchantId = searchParams.get('merchant_id');

    const maxAge = 2_592_000; // 30 days.
    const domain = new URL(window.location.href).hostname.split('.').slice(-2).join('.');

    if (rwgToken && merchantId) {
        storage.setItem(MERCHANT_ID_STORAGE_KEY, merchantId === getMerchantId() ? '2' : '1');
        document.cookie = `_rwg_token=${decodeURIComponent(rwgToken)};max-age=${maxAge};domain=${domain};path=/`;
        document.cookie = `_merchant_id=${merchantId};max-age=${maxAge};domain=${domain};path=/`;
    }
}

export function getRwgToken(): string | undefined {
    const rwgToken = document.cookie.match(/_rwg_token=([^;]+)/)?.[1];
    return rwgToken;
}

export function getMerchantId(): string | undefined {
    const merchantId = document.cookie.match(/_merchant_id=([^;]+)/)?.[1];
    return merchantId;
}

export { useSubmitGoogleConversionToken, setRwgTokenFromUrlParamsToCookie };
