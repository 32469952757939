import { GlobalStyles } from '@village/ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { addDays } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';

import { BookingContextProvider, LocationWidgetBookingContextProvider } from 'contexts/booking';
import { AppOptionsContextProvider } from 'contexts/app-options';
import { queryClient } from 'modules/react-query';
import { ThemeProvider } from 'theme/theme-provider';
import { Booking, Appointment, WidgetAppOptions } from 'types';
import { LocationWidget } from 'widget/location-widget';
import { ProviderWidget } from 'widget/provider-widget';
import { CaptureCountlyEventsContextProvider } from 'contexts/capture-countly-events';
import { WidgetContainer } from 'theme';
import { formatDate } from 'utils/date';
import { appConfig } from 'modules/app-config';
import { addGoogleTagManagerEvent } from 'modules/google-tag-manager';
import { SchedulerErrorBoundary } from 'components/error-boundary/error-boundary';
import { isClinicClosedForToday } from 'utils/department';

interface Props {
    options: WidgetAppOptions;
}

const WidgetScheduler: FC<Props> = ({ options }) => {
    const handleSelectAppointment = useCallback(
        (appointment: Appointment, booking: Booking) => {
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('date', formatDate(booking.date, 'iso'));
            const urlParams = new URLSearchParams({
                marketKey: booking.market?.market_key ?? '',
                departmentId: appointment.departmentid.toString(),
                reasonId: booking.reason?.id.toString() ?? '',
                providerId: booking.provider?.providerid.toString() ?? appointment.providerid.toString(),
                date: formatDate(appointment.date, 'iso'),
                appointmentId: appointment.appointmentid.toString(),
                isExistingPatient: (!!booking.isExistingPatient).toString(),
                appointmentSlotOrigin: options.type === 'providerWidget' ? 'widget-provider' : 'widget-location',
                previousPage: encodeURIComponent(currentUrl.toString()),
            });

            addGoogleTagManagerEvent('select-appointment-slot', booking);
            window.location.assign(new URL(`${appConfig.widgetRedirectToUrl}#/patient-information?${urlParams.toString()}`));
        },
        [options.type]
    );

    const bookingInitial = useMemo<Partial<Booking>>(() => {
        return {
            isExistingPatient: false,
            date: options.date ?? (isClinicClosedForToday(options) ? addDays(new Date(), 1) : new Date()),
        };
    }, [options]);

    const BookingProvider = options.type === 'locationWidget' ? LocationWidgetBookingContextProvider : BookingContextProvider;

    return (
        <ThemeProvider>
            <WidgetContainer>
                <AppOptionsContextProvider options={options}>
                    <QueryClientProvider client={queryClient}>
                        <SchedulerErrorBoundary>
                            <BookingProvider bookingInitial={bookingInitial}>
                                <CaptureCountlyEventsContextProvider>
                                    {options.type === 'locationWidget' ? (
                                        <LocationWidget onSelectAppointment={handleSelectAppointment} />
                                    ) : (
                                        <ProviderWidget onSelectAppointment={handleSelectAppointment} />
                                    )}
                                </CaptureCountlyEventsContextProvider>
                            </BookingProvider>
                        </SchedulerErrorBoundary>
                        <GlobalStyles />
                    </QueryClientProvider>
                </AppOptionsContextProvider>
            </WidgetContainer>
        </ThemeProvider>
    );
};

export { WidgetScheduler };
