import { useContext } from 'react';

import { BookingContext, BookingContextType } from 'contexts/booking';

const useBooking = (): BookingContextType => {
    const context = useContext(BookingContext);

    if (context === undefined) {
        throw new Error('useBooking must be used within a BookingContextProvider');
    }

    return context;
};

export { useBooking };
