import { InferType } from 'yup';
import { without, uniq } from 'ramda';
import { compareAsc, parse, differenceInHours } from 'date-fns';
import { ToDateOptionsWithTZ, format as formatTz, toZonedTime } from 'date-fns-tz';
import { CountlyEvent } from '@village/tools';
import { isAxiosError, HttpStatusCode } from 'axios';

import {
    Appointment,
    AppointmentApi,
    AppointmentQueryParams,
    Booking,
    CountlyKey,
    CountlySegmentationParams,
    Department,
    Market,
    Reason,
} from 'types';
import { formatDate } from './date';
import { IN_CLINIC_MIN_SCHEDULING_HOURS, IN_CLINIC_MAX_SCHEDULING_HOURS } from 'constants/appointment';
import { UseFreezeAppointmentParams } from 'hooks';
import { storage } from './storage';
import { appointmentDetailsStorageSchema } from 'schemas/storage';
import { getAllowedAppointmentTypeIds } from './provider';
import { aceApi } from 'api/ace';
import { DefaultTimeZone, TimeZoneOptions } from 'types/date';

/**
 * @description get the department associated with the appointment
 * @param {Department[]} departments the departments to search
 * @param {AppointmentApi} appointment the appointment to find the department for
 * @returns {Department | undefined} the department associated with the appointment or undefined if not found
 */
const getDepartmentByAppointment = (departments: Department[] | undefined, appointment: AppointmentApi): Department | undefined =>
    departments?.find(({ departmentid }) => departmentid === Number(appointment.departmentid));

/**
 * @description get the time zone options for the appointment
 * @param {Department | undefined} department the department associated with the appointment
 * @param {DefaultTimeZone} defaultTimeZone the default time zone to use
 * @returns {TimeZoneOptions} the time zone options
 */
const getTimeZoneOptions = (department: Department | undefined, defaultTimeZone: DefaultTimeZone): TimeZoneOptions => ({
    timeZone: department?.timezonename ?? defaultTimeZone,
});

/**
 * @description get the parsed timestamp for the appointment
 * @param {AppointmentApi} appointment the appointment to get the timestamp for
 * @param {TimeZoneOptions} timeZoneOptions the time zone options to use
 * @returns {number} the parsed timestamp
 */
const getDateTimeParsedTimestamp = (appointment: AppointmentApi, timeZoneOptions: TimeZoneOptions): number =>
    Number(formatTz(parse(`${appointment.date} ${appointment.starttime}`, 'MM/dd/yyyy HH:mm', new Date()), 'T', timeZoneOptions));

/**
 * @description get the department time now timestamp
 * @param {TimeZoneOptions} timeZoneOptions the time zone options to use
 * @returns {number} the department time now timestamp
 */
const getDepartmentTimeNowTimestamp = (timeZoneOptions: TimeZoneOptions): number =>
    Number(formatTz(toZonedTime(new Date(), timeZoneOptions.timeZone), 'T', timeZoneOptions));

/**
 * @description create an appointment object from the appointment api response
 * @param {AppointmentFactoryParams} params an object containing the appointment api response, departments, and default time zone
 * @returns {Appointment} the appointment object
 */
const appointmentFactory = (appointment: AppointmentApi, departments: Department[], reason: Reason | undefined): Appointment => {
    const department = departments.find(({ departmentid }) => departmentid === Number(appointment.departmentid));
    const timeZoneOptions: ToDateOptionsWithTZ & { timeZone: string } = {
        timeZone: department?.timezonename ?? 'America/Chicago',
    };

    const dateTimeParsedTimestamp = Number(
        formatTz(parse(`${appointment.date} ${appointment.starttime}`, 'MM/dd/yyyy HH:mm', new Date()), 'T', timeZoneOptions)
    );

    const departmentTimeNowTimestamp = Number(formatTz(toZonedTime(new Date(), timeZoneOptions.timeZone), 'T', timeZoneOptions));

    const minHoursToSchedule = reason?.scheduling_min_hours ?? IN_CLINIC_MIN_SCHEDULING_HOURS;
    const maxHoursToSchedule = reason?.scheduling_max_days ? reason.scheduling_max_days * 24 : IN_CLINIC_MAX_SCHEDULING_HOURS;
    const diffInHours = differenceInHours(dateTimeParsedTimestamp, departmentTimeNowTimestamp);

    return {
        appointmentid: Number(appointment.appointmentid),
        appointmenttype: appointment.appointmenttype,
        appointmenttypeid: Number(appointment.appointmenttypeid),
        patientappointmenttypename: appointment.patientappointmenttypename,
        date: appointment.date,
        dateTimeParsed: new Date(dateTimeParsedTimestamp),
        duration: Number(appointment.duration),
        departmentid: Number(appointment.departmentid),
        providerid: Number(appointment.providerid),
        starttime: appointment.starttime,
        displayStartTime: formatDate(dateTimeParsedTimestamp, 'timeTwelveHour'),
        withinAllowedTimeRange: diffInHours >= minHoursToSchedule && diffInHours <= maxHoursToSchedule,
    };
};

const sortAppointmentsByFirstAvailable = (
    appointmentA: Appointment | undefined,
    appointmentB: Appointment | undefined
): number => {
    const dateA = appointmentA?.dateTimeParsed;
    const dateB = appointmentB?.dateTimeParsed;

    if (dateA && dateB) {
        return compareAsc(dateA, dateB);
    } else if (dateA && !dateB) {
        return -1;
    } else if (!dateA && dateB) {
        return 1;
    } else {
        return 0;
    }
};

const getFrozenAppointmentsIds = (): number[] => {
    const currentString = storage.getItem(FROZEN_APPOINTMENTS_KEY);
    return currentString ? currentString.split(',').map(Number) : [];
};

const FROZEN_APPOINTMENTS_KEY = 'FROZEN_APPOINTMENTS';
const toggleFrozenAppointmentInStorage = (params: UseFreezeAppointmentParams): void => {
    const current = getFrozenAppointmentsIds();

    if (params.freeze) {
        storage.setItem(FROZEN_APPOINTMENTS_KEY, uniq([...current, params.appointmentid]).toString());
    } else {
        storage.setItem(FROZEN_APPOINTMENTS_KEY, without([params.appointmentid], current).toString());
    }
};

/**
 * Save the booked appointment to local storage
 * so we can use it later to prefill the appointment details form
 */
const LAST_BOOKED_APPOINTMENT_KEY = 'LAST_BOOKED_APPOINTMENT';
const saveBookedAppointmentDetailsInStorage = (booking: Booking): void => {
    if (booking.appointment && booking.market && booking.reason) {
        storage.setItem(
            LAST_BOOKED_APPOINTMENT_KEY,
            JSON.stringify({
                timestamp: Date.now(),
                departmentId: booking.appointment.departmentid,
                marketKey: booking.market.market_key,
                appointmentId: booking.appointment.appointmentid,
                reasonId: booking.reason.id,
            })
        );
    }
};

const getLastBookedAppointmentFromStorage = () => {
    try {
        const currentString = storage.getItem(LAST_BOOKED_APPOINTMENT_KEY);

        const detailsToValidate = currentString
            ? (JSON.parse(currentString) as InferType<typeof appointmentDetailsStorageSchema>)
            : undefined;

        // `validateSync` throws an error if the object is not according to the schema
        const validated = appointmentDetailsStorageSchema.validateSync(detailsToValidate);

        return {
            marketKey: validated.marketKey,
            departmentId: validated.departmentId,
            reasonId: validated.reasonId,
            isExistingPatient: Boolean(validated.departmentId && validated.marketKey),
        };
    } catch {
        storage.removeItem(LAST_BOOKED_APPOINTMENT_KEY);
        return undefined;
    }
};

const getFirstNextAppointment = (
    appointments: Appointment[] | undefined,
    department: Department | undefined,
    market: Market | undefined,
    providerId: number | undefined,
    reason: Reason | undefined
) => {
    if (!appointments || appointments.length === 0 || !department || !market || !providerId || !reason) {
        return null;
    }

    const allowedAppointmentTypeIds = getAllowedAppointmentTypeIds(reason, market, department.departmentid);

    const nextAppointments =
        allowedAppointmentTypeIds.length > 0
            ? appointments.filter(
                  ({ appointmenttypeid, providerid }) =>
                      allowedAppointmentTypeIds.includes(appointmenttypeid) && providerid === providerId
              )
            : appointments;

    return nextAppointments.length > 0 ? nextAppointments[0] : null;
};

const getAppointmentsQuery = (
    params: AppointmentQueryParams,
    signal: AbortSignal | undefined,
    market: Market | undefined,
    practice = 'villagefamilypractice'
): Promise<{ data: { appointments: AppointmentApi[] } }> =>
    aceApi.get<{ appointments: AppointmentApi[] }>(`/${market?.market_key}/${practice}/athena/appointments/open`, {
        params,
        signal,
    });

/**
 * @description Clears the appointment from the booking state if there was a conflict and sends a Countly bookingError event
 * @returns {number | undefined} The HTTP status code of the API error, or undefined if not applicable.
 */
const handleBookAppointmentError = (
    error: unknown,
    setBookingField: { (partialBooking: Partial<Booking>): void },
    addCountlyEvent: { (event: CountlyEvent<CountlyKey, CountlySegmentationParams>): void }
): number | undefined => {
    const apiErrorStatus = isAxiosError(error) ? error.response?.status : undefined;

    /**
     * The appointment selected is no longer available
     * or cannot not be booked
     */
    if (apiErrorStatus === HttpStatusCode.Conflict) {
        setBookingField({ appointment: undefined });
    }

    addCountlyEvent({
        key: 'bookingError',
        segmentation: { status: apiErrorStatus ?? 'UNKNOWN_STATUS' },
    });

    return apiErrorStatus;
};

export {
    appointmentFactory,
    getAppointmentsQuery,
    getDateTimeParsedTimestamp,
    getDepartmentByAppointment,
    getDepartmentTimeNowTimestamp,
    getFirstNextAppointment,
    getFrozenAppointmentsIds,
    getLastBookedAppointmentFromStorage,
    getTimeZoneOptions,
    handleBookAppointmentError,
    toggleFrozenAppointmentInStorage,
    saveBookedAppointmentDetailsInStorage,
    sortAppointmentsByFirstAvailable,
    type DefaultTimeZone,
};
