import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { groupBy, toPairs } from 'ramda';

import { aceApi } from 'api/ace';
import { reasonFactory } from 'utils/reason';
import type { Booking, Reason, ReasonApi } from 'types';

const useReasons = (booking: Booking): UseQueryResult<Reason[]> => {
    const { isExistingPatient, market, departmentAndRelatedDepartments } = booking;

    const plainParams = {
        reason_type: isExistingPatient ? 'existing' : 'new',
    };

    return useQuery({
        queryKey: ['reasons', plainParams, departmentAndRelatedDepartments],
        queryFn: async () => {
            const queries = departmentAndRelatedDepartments.map(({ departmentid }) =>
                aceApi.get<{ reasons: ReasonApi[] }>(`/${market?.market_key}/${departmentid}/vmd-reasons`, {
                    params: plainParams,
                })
            );

            const responses = await Promise.all(queries).then((allResponses) =>
                allResponses
                    .flatMap(({ data }) => data.reasons)
                    // Sometimes backend returns null or undefined
                    // Reference: https://app.datadoghq.com/rum/error-tracking/issue/5291dd4e-5cb2-11ee-a68c-da7ad0900002
                    .filter((reason) => reason)
            );

            // We group the reasons by id to avoid duplicates and to support multiple reasons per department
            // This is mainly to support related departments (e.g. "Thunderbird")
            // We also convert the grouped reasons to an array of reasons to be used to get the departmentConfigs
            const reasonsGroupedById = groupBy(({ id }) => id.toString(), responses);
            return toPairs(reasonsGroupedById).map(([, reasonGroup]) => reasonFactory(reasonGroup));
        },
        enabled: Boolean(market?.market_key && departmentAndRelatedDepartments.length),
    });
};

export { useReasons };
