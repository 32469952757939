import { prop } from 'ramda';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';

import { aceApi } from 'api/ace';
import type { Booking, CareTeam, Provider } from 'types';
import { useProviders } from './use-providers';

const getPlainParams = (booking: Booking) => ({
    market: booking.market?.market_key,
    npis: booking.provider?.npi,
    department_ids: booking.departmentAndRelatedDepartments.map(prop('departmentid')).join(),
});

const fetchCareTeams = async (params: ReturnType<typeof getPlainParams>): Promise<CareTeam[]> => {
    try {
        const response = await aceApi.get<{ results: CareTeam[] }>(
            `/${params.market}/villagefamilypractice/athena/care-team-npis`,
            { params }
        );

        return response.data?.results ?? [];
    } catch {
        // To avoid showing error screen
        // Care teams is optional feature, patient should still book an appointment without it.
        return [];
    }
};

const useCareTeam = (booking: Booking): UseQueryResult<Provider[]> => {
    const { data: providers } = useProviders(booking);
    const params = getPlainParams(booking);

    return useQuery({
        queryKey: ['care-team', params],
        queryFn: async () => {
            const response = await fetchCareTeams(params);

            const careTeam = response
                .flatMap(({ care_team }) => care_team)
                .map<Provider | undefined>((npi) => providers?.find((provider) => provider.npi === npi))
                .filter((provider): provider is Provider => provider !== undefined);

            return careTeam;
        },
        throwOnError: false,
        enabled: Boolean(booking.provider?.npi && params.department_ids),
    });
};

export { useCareTeam };
