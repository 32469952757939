import { useContext } from 'react';

import { CaptureCountlyEvents, CaptureCountlyEventsContext } from 'contexts/capture-countly-events';

const useCaptureCountlyEvent = (): CaptureCountlyEvents => {
    const context = useContext(CaptureCountlyEventsContext);

    if (context === undefined) {
        throw new Error('useCaptureCountlyEvent must be used within a CaptureCountlyEventsContextProvider');
    }

    return context;
};

export { useCaptureCountlyEvent };
