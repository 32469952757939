import { Radio, Text } from '@village/ui';
import styled from 'styled-components';

const Label = styled(Text).attrs(() => ({ type: 'caption1', tag: 'label' }))`
    display: block;
    margin-bottom: 0.375rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const RadioValuesContainer = styled.div<{ $hasError: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    border: ${({ $hasError }) => ($hasError ? 2 : 1)}px solid
        ${({ theme, $hasError }) =>
            $hasError ? theme.vui.colors.text.error : theme.vui.component.input.default.default.borderColor}!important;
    border-radius: 4px;

    @media (hover: hover) {
        &:hover {
            border-color: ${({ theme }) => theme.vui.component.input.default.hover.borderColor}!important;
        }
    }
`;

const RadioInput = styled(Radio)`
    height: calc(3rem - 2px);
    padding: 0 0.75rem;
    border-color: inherit;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};

    &:last-of-type {
        border-left-width: 1px;
        border-left-style: solid;
    }
`;

const ErrorContainer = styled(Text)`
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.vui.colors.text.error};
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;

export { Label, RadioValuesContainer, RadioInput, ErrorContainer };
