import React from 'react';

import * as Styled from './styles';

interface OverlappingAvatarsProps {
    avatars: string[];
}

export const OverlappingAvatars: React.FC<OverlappingAvatarsProps> = ({ avatars }) => {
    return (
        <Styled.AvatarContainer>
            {avatars.map((imageUrl, index) => (
                <Styled.AvatarWrapper key={index} index={index}>
                    <Styled.AvatarImage style={{ backgroundImage: `url(${imageUrl})` }} />
                </Styled.AvatarWrapper>
            ))}
        </Styled.AvatarContainer>
    );
};
