import styled from 'styled-components';

export const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const AvatarWrapper = styled.div<{ index: number }>`
    margin-left: ${(props) => (props.index === 0 ? '0' : `-9px`)};
    z-index: ${(props) => 3 - props.index};
`;

export const AvatarImage = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
`;
