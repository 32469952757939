import { ChangeEvent, FC, useCallback } from 'react';

import { useBooking, useCaptureCountlyEvent } from 'hooks';
import { addGoogleTagManagerEvent } from 'modules/google-tag-manager';
import * as Styled from './styles';

export const IsExistingPatientField: FC = () => {
    const { setBookingField, booking } = useBooking();
    const { addCountlyEvent } = useCaptureCountlyEvent();

    const handleOnChange = useCallback(
        (event: ChangeEvent<object>) => {
            if (!booking.reason) {
                addCountlyEvent({ key: 'selectNewExistingPatient' });
            } else {
                addCountlyEvent({ key: 'updateExistingPatient' });
            }

            const isExistingPatient = (event as ChangeEvent<HTMLInputElement>).target.value === 'yes';
            setBookingField({ isExistingPatient });
            addGoogleTagManagerEvent('is-previous-patient', { isExistingPatient });
        },
        [booking.reason, addCountlyEvent, setBookingField]
    );

    return (
        <div>
            <Styled.ValuesContainer data-role="radiogroup">
                <Styled.RadioInput
                    id="is-existing-patient-radio-no"
                    label="I'm a new patient"
                    onChange={handleOnChange}
                    data-testid="is-existing-patient-radio-no"
                    name="isExistingPatient"
                    value="no"
                    checked={booking.isExistingPatient === false}
                />
                <Styled.RadioInput
                    id="is-existing-patient-radio-yes"
                    label="I'm a returning patient"
                    onChange={handleOnChange}
                    data-testid="is-existing-patient-radio-yes"
                    name="isExistingPatient"
                    value="yes"
                    checked={booking.isExistingPatient === true}
                />
            </Styled.ValuesContainer>
        </div>
    );
};
