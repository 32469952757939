const installPolyFills = async () => {
    // Conditionally load the `resize-observer` polyfill
    // if the browser doesn't support it
    if (!window.ResizeObserver) {
        const { install } = await import('resize-observer');
        install();
    }

    // Conditionally load the `intersection-observer` polyfill
    // if the browser doesn't support it
    if (!window.IntersectionObserver) {
        await import('intersection-observer');
    }
};

export { installPolyFills };
