import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    height: 6rem;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export { Container, Wrapper };
