import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';

import { departmentFactory } from 'utils/department';
import type { Booking, DepartmentByIdResponse, Department } from 'types';
import { aceApiV2 } from 'api/ace';

interface Params {
    market?: string | null;
}

const getPlainParams = (params: Params) => ({
    market: params.market,
    provider_list: true,
});

/**
 * @returns departments: list of departments including the one with matching id and its related departments
 */
const useDepartmentById = (booking: Booking, departmentid: number): UseQueryResult<Department[]> => {
    const marketKey = booking?.market?.market_key;
    const plainParams = getPlainParams({ market: marketKey });

    return useQuery({
        queryKey: ['departments-by-id', plainParams],
        queryFn: async (): Promise<Department[]> => {
            const { data } = await aceApiV2.get<DepartmentByIdResponse>(`departments/${departmentid}`, { params: plainParams });
            return [departmentFactory(data.data), ...data.related_departments.map((department) => departmentFactory(department))];
        },
        enabled: Boolean(marketKey && booking.isExistingPatient !== undefined),
    });
};

export { useDepartmentById };
