import { useCallback, useEffect, useState } from 'react';

import { sizes as vmdSizes } from 'utils/device';
import { sizes as cmdSizes } from 'citymd/constants/device';
import { useAppOptions } from 'hooks';

const useIsMobile = (): boolean => {
    const { type } = useAppOptions();
    const maxSize = type === 'citymd' ? cmdSizes.mobile : vmdSizes.tablet;
    const [isMobileView, setIsMobileView] = useState((document.body?.clientWidth ?? 0) <= maxSize);

    const handleWindowResize = useCallback(() => {
        setIsMobileView((document.body?.offsetWidth ?? 0) <= maxSize);
    }, [maxSize]);

    useEffect(() => {
        const client = new ResizeObserver(handleWindowResize);
        client.observe(document.body);
        return () => client.disconnect();
    }, [handleWindowResize]);

    return isMobileView;
};

export { useIsMobile };
