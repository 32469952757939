import { WidgetAppOptions } from 'types';

export const isWidgetSchedulingEnabled = (widgetOptions: WidgetAppOptions): boolean => {
    // Some locations are enabled for scheduling but they have
    // `widgetOptions.departmentId: 0` in the config
    if (widgetOptions.enabledForScheduling) {
        if (widgetOptions.type === 'locationWidget' && !widgetOptions.departmentId) {
            return false;
        }

        // Some providers are enabled for scheduling but they have
        // `widgetOptions.providerNpi: 0` and/or `widgetOptions.departmentsList: []` in the config
        if (
            widgetOptions.type === 'providerWidget' &&
            (!widgetOptions.providerNpi || widgetOptions.departmentsList.length === 0)
        ) {
            return false;
        }
    } else {
        return false;
    }

    return true;
};
