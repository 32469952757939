import { useCallback, useMemo, FC, useState, useEffect } from 'react';
import { Button, Spinner, Text } from '@village/ui';
import { prop } from 'ramda';
import { differenceInCalendarDays, addDays } from 'date-fns';

import * as Styled from './styles';
import { formatDate } from 'utils/date';
import { AppointmentsByDepartmentProvider } from 'types';
import { useBooking, useRemoteConfig, useNextAvailableAppointments, useCareTeam, useCaptureCountlyEvent } from 'hooks';
import { getFirstNextAppointment } from 'utils/appointment';

interface Props {
    isInView: boolean;
    grouping: AppointmentsByDepartmentProvider;
}

const NextAvailableAppointments: FC<Props> = ({ isInView, grouping }) => {
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { booking, setBookingField } = useBooking();
    const { market, date, department, reason, departmentAndRelatedDepartments } = booking;
    const { data: careTeam } = useCareTeam(booking);
    const [isNextAppointmentsSecondAttempt, setNextAppointmentsSecondAttempt] = useState(false);
    const { nextAvailableDateRange, nextAvailableDateRangeSecondary } = useRemoteConfig();

    // Start date:
    // First attempt => +1 (To exclude today)
    // Second attempt => +nextAvailableDateRange +1 (To exclude today)
    const { startDate, dateRange, fullDateRange } = useMemo(() => {
        if (!isNextAppointmentsSecondAttempt) {
            return {
                dateRange: nextAvailableDateRange,
                startDate: addDays(date, 1),
                fullDateRange: nextAvailableDateRange,
            };
        }

        return {
            dateRange: nextAvailableDateRangeSecondary,
            startDate: addDays(date, nextAvailableDateRange + 1),
            fullDateRange: nextAvailableDateRange + nextAvailableDateRangeSecondary,
        };
    }, [isNextAppointmentsSecondAttempt, nextAvailableDateRangeSecondary, nextAvailableDateRange, date]);

    const {
        data: appointments,
        isFetching,
        isError,
        isFetched,
    } = useNextAvailableAppointments(
        booking,
        {
            providerIds: grouping.provider?.providerid ? [grouping.provider.providerid] : [],
            startDate: formatDate(startDate, 'monthDayYear'),
            range: dateRange,
            bypassScheduleTimeChecks: market?.bypass_schedule_time_checks ?? false,
            departmentIds: departmentAndRelatedDepartments.map(prop('departmentid')),
            marketKey: market?.market_key,
            reasonId: reason?.id,
        },
        { enabled: isInView }
    );

    const firstNextAppointment = useMemo(
        () => getFirstNextAppointment(appointments, department, market, grouping.provider?.providerid, reason),
        [appointments, reason, department, market, grouping.provider?.providerid]
    );

    const handleSetDate = useCallback(
        (inputDate: string) => {
            const daysUntilNextAppointment = differenceInCalendarDays(new Date(inputDate), new Date());
            const isCareTeam = careTeam?.find(({ npi }) => npi === grouping.provider.npi);

            addCountlyEvent({
                key: 'clickFindAppointmentsNextDate',
                segmentation: {
                    isCareTeam: isCareTeam ? 'Yes' : 'No',
                    nextAvailableAppointmentInDays: daysUntilNextAppointment,
                    providerId: grouping.provider.providerid,
                    providerName: grouping.provider.displayname,
                },
            });

            setBookingField({ date: new Date(inputDate) });
        },
        [setBookingField, addCountlyEvent, grouping.provider, careTeam]
    );

    const handleIncrementDateRange = useCallback(() => {
        const isCareTeam = careTeam?.find(({ npi }) => npi === grouping.provider.npi);

        addCountlyEvent({
            key: 'clickFindAppointmentsNext',
            segmentation: {
                isCareTeam: isCareTeam ? 'Yes' : 'No',
                providerId: grouping.provider.providerid,
                providerName: grouping.provider.displayname,
            },
        });

        setNextAppointmentsSecondAttempt(true);
    }, [addCountlyEvent, grouping.provider, careTeam]);

    useEffect(() => {
        setNextAppointmentsSecondAttempt(false);
    }, [date]);

    if (isFetching) {
        return (
            <Styled.SpinnerContainer>
                <Spinner />
            </Styled.SpinnerContainer>
        );
    }

    if (!isFetched) {
        return null;
    }

    if (isError) {
        return <Text type="body1">Could not load next available appointments for this provider.</Text>;
    }

    if (firstNextAppointment) {
        return (
            <Styled.AppointmentsNextAvailableContainer>
                <Styled.NoAvailabilityContainer>No available appointments for the selected dates</Styled.NoAvailabilityContainer>
                <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => handleSetDate(firstNextAppointment.date)}
                    data-testid="next-available-appointment"
                    fullWidth={true}
                >
                    Next {reason?.is_in_person === false ? 'video' : 'in-office'} visit:{' '}
                    {formatDate(firstNextAppointment.dateTimeParsed, 'monthDayShort')}
                </Button>
            </Styled.AppointmentsNextAvailableContainer>
        );
    }

    return (
        <Styled.AppointmentsNextAvailableContainer>
            <Styled.NoAvailabilityContainer>
                No availability for this provider in the next {fullDateRange} days
            </Styled.NoAvailabilityContainer>
            {!isNextAppointmentsSecondAttempt ? (
                <Button variant="secondary" size="medium" onClick={handleIncrementDateRange} fullWidth={true}>
                    Find next available
                </Button>
            ) : null}
        </Styled.AppointmentsNextAvailableContainer>
    );
};

export { NextAvailableAppointments };
