import { groupBy, sort, uniq, fromPairs } from 'ramda';

import type { Reason, ReasonApi, Provider, Department, DepartmentReasonConfig } from 'types';

const getProvidersByAppointmentTypeId = (providerMapping: ReasonApi['provider_mappings']) =>
    uniq(providerMapping.flatMap(({ appointment_type_ids }) => appointment_type_ids)).reduce<Record<string, number[]>>(
        (accumulator, typeId) => ({
            ...accumulator,
            [typeId]: providerMapping
                .filter(({ appointment_type_ids }) => appointment_type_ids.includes(typeId))
                .map(({ provider_id }) => provider_id),
        }),
        {}
    );

const getAppointmentTypeIdsByProvider = (providerMapping: ReasonApi['provider_mappings']) =>
    groupBy(({ provider_id }) => provider_id.toString(), providerMapping);

const reasonFactory = (reasonGroup: ReasonApi[]): Reason => {
    const departmentIdAndReasonMappings = reasonGroup.map<[number, DepartmentReasonConfig]>(
        ({ department_id, provider_mappings }) => [
            department_id,
            {
                providerIdsMappings: getAppointmentTypeIdsByProvider(provider_mappings),
                providerIdsByAppointmentTypeId: getProvidersByAppointmentTypeId(provider_mappings),
            },
        ]
    );

    return {
        ...reasonGroup[0],
        visitType: reasonGroup[0].is_in_person ? 'IN_OFFICE' : 'VIDEO',
        emr_reason_id: reasonGroup[0].emr_reason_id != null ? Number(reasonGroup[0].emr_reason_id) : null,
        departmentConfigs: fromPairs(departmentIdAndReasonMappings),
    };
};

const getReasonTitle = (reason: Reason): string => `${reason.name} (${reason.is_in_person ? 'in-office' : 'video'})`;

/**
 * We are using `book_as_appointment_type_id` field because village business logic helps us respect provider preferences
 * For how appointments are reflected in their schedules
 */
const getBookAppointmentTypeForReason = (reason: Reason, departmentId: number, providerId: number): number | null => {
    const departmentConfig = reason.departmentConfigs[departmentId];

    if (!departmentConfig) {
        return null;
    }

    const reasonMappings = departmentConfig.providerIdsMappings[providerId];
    return reasonMappings?.[0]?.book_as_appointment_type_id ?? null;
};

/**
 * Keep only the reasons that map to at least one provider
 * So we don't end-up with a reason that shows an empty providers list
 */
const filterReasonsByProviders = (
    reasons: Reason[],
    providers: Provider[],
    departmentAndRelatedDepartments: Department[]
): Reason[] => {
    return reasons.filter((reason) => {
        return providers.some(({ providerid }) =>
            departmentAndRelatedDepartments.some(
                ({ departmentid }) => reason.departmentConfigs[departmentid]?.providerIdsMappings[providerid]?.length > 0
            )
        );
    });
};

/**
 * /!\ Check with product before changing this order
 * because its used to sort the reasons
 *
 * Note: We use array of names because some reasons have different names in different environments
 */
const REASONS_ORDER = [
    {
        visitType: 'IN_OFFICE',
        names: ['Problem/Sick Visit'],
    },
    {
        visitType: 'IN_OFFICE',
        names: ['Adult Problem/Sick Visit'],
    },
    {
        visitType: 'VIDEO',
        names: ['Problem/Sick Visit'],
    },
    {
        visitType: 'VIDEO',
        names: ['Adult Problem/Sick Visit'],
    },
    {
        visitType: 'IN_OFFICE',
        names: ['Adult Annual Physical'],
    },
    {
        visitType: 'IN_OFFICE',
        names: ['Follow-up', 'Follow Up'],
    },
    {
        visitType: 'VIDEO',
        names: ['Follow-up', 'Follow Up'],
    },
    {
        visitType: 'IN_OFFICE',
        names: ['Medicare Annual Wellness Visit'],
    },
    {
        visitType: 'VIDEO',
        names: ['Medicare Annual Wellness Visit'],
    },
];

/**
 * Use the order defined in `REASONS_ORDER` to sort the reasons
 * If a reason is not in the order list, it will be sorted alphabetically
 */
const sortReasons = (reasons: Reason[]): Reason[] => {
    return sort((a, b) => {
        const aIndex = REASONS_ORDER.findIndex((reason) => reason.names.includes(a.name) && reason.visitType === a.visitType);
        const bIndex = REASONS_ORDER.findIndex((reason) => reason.names.includes(b.name) && reason.visitType === b.visitType);

        if (aIndex === -1 && bIndex === -1) {
            return a.name.localeCompare(b.name);
        }

        if (aIndex === -1) {
            return 1;
        }

        if (bIndex === -1) {
            return -1;
        }

        return aIndex - bIndex;
    }, reasons);
};

export { reasonFactory, getReasonTitle, getBookAppointmentTypeForReason, filterReasonsByProviders, sortReasons };
