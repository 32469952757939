import { bookingParamsSchema } from 'schemas/app-config';
import { BookingParamsSchema } from 'types';

const getWidgetBookingParamsFromUrl = (): BookingParamsSchema | undefined => {
    try {
        const params = Object.fromEntries(new URLSearchParams(window.location.search));
        return bookingParamsSchema.validateSync(params);
    } catch {
        return undefined;
    }
};

export { getWidgetBookingParamsFromUrl };
