import { Text } from '@village/ui';
import styled, { css } from 'styled-components';

const Container = styled.section`
    width: 100%;
`;

const Title = styled(Text).attrs(() => ({ type: 'sub2' }))`
    display: grid;
    row-gap: 1.5rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const EmergencyNotice = styled(Text).attrs(() => ({ type: 'body2' }))`
    margin: 0.25rem 0 1.5rem;
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;

const FieldsWrapper = styled.div`
    display: grid;
    row-gap: 1.5rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const DepartmentFieldWrapper = styled.div`
    display: grid;
    row-gap: 1.5rem;
`;

const RelativeContainer = styled.div`
    position: relative;
    min-height: 8rem;
`;

const AppointmentsContainer = styled.div<{ $loading: boolean }>`
    ${({ $loading }) =>
        $loading &&
        css`
            background-color: rgba(256, 256, 256, 0.7);
            pointer-events: none;
        `};
`;

const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(256, 256, 256, 0.7);
    pointer-events: none;
    z-index: 1;
`;

const SpinnerWrapper = styled.div`
    position: sticky;
    top: 10rem;
`;

export {
    Container,
    Title,
    FieldsWrapper,
    EmergencyNotice,
    DepartmentFieldWrapper,
    AppointmentsContainer,
    SpinnerContainer,
    SpinnerWrapper,
    RelativeContainer,
};
