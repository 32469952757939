import React from 'react';
import { Link, Text } from '@village/ui';
import { ChevronRightIcon } from '@village/icons';

import { Department, Provider } from 'types';
import * as Styled from './styles';
import { OverlappingAvatars } from '../overlapping-avatars';
import { HUBSPOT_PROVIDER_IMAGE_DEFAULT } from 'constants/hubspot';
import { useCaptureCountlyEvent } from 'hooks';

interface Props {
    department: Department;
    providers: Provider[];
    onViewMoreProviders: () => void;
}

const BeSeenSoonerBanner: React.FC<Props> = ({ department, providers, onViewMoreProviders }) => {
    const { addCountlyEvent } = useCaptureCountlyEvent();

    const providerImages = providers
        .sort((a) => (a.thumbnailUrl === HUBSPOT_PROVIDER_IMAGE_DEFAULT ? 1 : -1))
        .slice(0, 2)
        .map((provider) => provider.thumbnailUrl);

    const handleViewMoreProviders = () => {
        addCountlyEvent({ key: 'clickViewMoreProviders' });
        onViewMoreProviders();
    };

    return (
        <Styled.Container data-testid="be-seen-sooner-banner">
            <OverlappingAvatars avatars={providerImages} />
            <Styled.Content>
                <Styled.Title>Need to be seen sooner?</Styled.Title>
                <Text type="body1">
                    View other providers at {department.hubspot?.friendlyName || department.name} who might be available to see
                    you.
                </Text>
                <Link onClick={handleViewMoreProviders} data-testid="view-more-providers-button">
                    View more providers <ChevronRightIcon size="xs" />
                </Link>
            </Styled.Content>
        </Styled.Container>
    );
};

export { BeSeenSoonerBanner };
