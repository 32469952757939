import { slice } from 'ramda';
import { FC, useMemo } from 'react';
import { format } from 'date-fns';

import { useIsMobile } from 'hooks';
import { Appointment } from 'types';
import { getRelativeDayOfTheWeek } from 'utils/date';
import * as Styled from './styles';

interface Props {
    appointments: (Appointment | null)[];
    date: string;
    slotsCount: number;
    onSelectAppointment: (appointment: Appointment) => void;
}

const AppointmentsColumn: FC<Props> = ({ appointments, date, slotsCount, onSelectAppointment }) => {
    const isMobile = useIsMobile();
    const appointmentsPadded = slice(0, slotsCount, appointments);

    const appointmentDate = new Date(date);

    const columnDay = getRelativeDayOfTheWeek(appointmentDate);
    const columnDate = format(appointmentDate, 'd MMM');

    const slotPlaceholderHeight = useMemo(() => {
        const slotButton = document.querySelector('[data-testid="button-timeslot"]');
        const placeholderHeight = slotButton?.getBoundingClientRect()?.height || isMobile ? 36 : 47;
        return placeholderHeight;
    }, [isMobile]);

    return (
        <Styled.AppointmentsColumn data-testid="appointment-column">
            <Styled.AppointmentsColumnDay>{columnDay}</Styled.AppointmentsColumnDay>
            <Styled.AppointmentsColumnDate>{columnDate}</Styled.AppointmentsColumnDate>
            <Styled.AppointmentList>
                {appointmentsPadded.map((appointment, appointmentIndex) =>
                    appointment ? (
                        <Styled.SlotButton
                            key={appointment.appointmentid}
                            data-appointment-id={appointment.appointmentid}
                            data-testid="button-timeslot"
                            size={isMobile ? 'small' : 'medium'}
                            variant="secondary"
                            onClick={() => onSelectAppointment(appointment)}
                            fullWidth={true}
                        >
                            {appointment.displayStartTime}
                        </Styled.SlotButton>
                    ) : (
                        <Styled.SlotPlaceholder key={appointmentIndex} $height={slotPlaceholderHeight}>
                            -
                        </Styled.SlotPlaceholder>
                    )
                )}
            </Styled.AppointmentList>
        </Styled.AppointmentsColumn>
    );
};

export { AppointmentsColumn };
