import type { Booking, GoogleTagManagerSchema, GoogleTagManagerEvent } from 'types';
import { getHyphenatedDepartmentAddress } from 'utils/department';
import { getReasonTitle } from 'utils/reason';

/*
 * In prod, Google Tag Manager is initialized within Hubspot located here:
 * https://app.hubspot.com/settings/6509886/website/pages/all-domains/page-templates
 */
const initGoogleTagManager = () => {
    window.dataLayer = window.dataLayer || [];
};

const addGoogleTagManagerEvent = (event: GoogleTagManagerEvent, booking: Partial<Booking>): void => {
    const googleTagManagerData: GoogleTagManagerSchema = {};

    if (booking.market) {
        googleTagManagerData.scheduler_region = booking.market.display_name;
    }
    if (booking.isExistingPatient !== undefined) {
        googleTagManagerData.scheduler_previous_patient = booking.isExistingPatient ? 'yes' : 'no';
    }
    if (booking.department) {
        googleTagManagerData.scheduler_location = getHyphenatedDepartmentAddress(booking.department);
    }
    if (booking.reason) {
        googleTagManagerData.scheduler_reason = getReasonTitle(booking.reason);
    }

    window.dataLayer?.push({ event: `scheduler-${event}`, ...googleTagManagerData });
};

export { addGoogleTagManagerEvent, initGoogleTagManager };
