import { FC, useEffect, useMemo } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { prop } from 'ramda';

import {
    useAppointmentsGroups,
    useBooking,
    useDepartmentById,
    useMarkets,
    useProviders,
    useReasons,
    useAppOptions,
    useCaptureCountlyEvent,
    useSchedulingNotAvailable,
    useRemoteConfig,
    useNextAvailableAppointments,
} from 'hooks';
import { Appointment, Booking } from 'types';
import { DatesNavigation, AppointmentsCard, SchedulingNotAvailable } from 'widget/components';
import { CenteredSpinner } from 'components/centered-spinner';
import { ReasonField } from 'widget/components/fields';
import { IsExistingPatientField } from 'components/fields/is-existing-patient';
import { getDefaultReason } from 'widget/utils/reason';
import { filterReasonsByProviders } from 'utils/reason';
import * as Styled from '../styles';
import { formatDate } from 'utils/date';
import { getFirstNextAppointment } from 'utils/appointment';

interface Props {
    onSelectAppointment: (appointment: Appointment, booking: Booking) => void;
}

const LocationWidget: FC<Props> = ({ onSelectAppointment }) => {
    const { setBookingField, booking } = useBooking();
    const { prnVisibleDateRange } = useRemoteConfig();
    const widgetOptions = useAppOptions('locationWidget');
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { data: markets, isFetching: isMarketsFetching } = useMarkets();
    const { data: departments, isFetching: isDepartmentsFetching } = useDepartmentById(booking, widgetOptions.departmentId);
    const { isFetching: isProvidersFetching, data: providers } = useProviders(booking);
    const { data: allReasons, isFetching: isReasonsFetching } = useReasons(booking);
    const { schedulingNotAvailableReason, setSchedulingUnavailableReason } = useSchedulingNotAvailable();

    const { isAppointmentsFetching, appointmentsGroupings } = useAppointmentsGroups({
        featuredProviderId: widgetOptions.providerId,
    });

    const prnProviderIds = useMemo(
        () => providers?.filter((provider) => provider.isprn).map(({ providerid }) => providerid),
        [providers]
    );

    // Fetch availability for PRN providers for current department within x days of the current date
    const { data: prnNextAvailableAppointments, isFetching: isNextAvailableFetching } = useNextAvailableAppointments(booking, {
        providerIds: prnProviderIds ?? [],
        startDate: formatDate(booking.date, 'monthDayYear'),
        range: prnVisibleDateRange,
        bypassScheduleTimeChecks: booking.market?.bypass_schedule_time_checks ?? false,
        departmentIds: booking.departmentAndRelatedDepartments.map(prop('departmentid')),
        marketKey: booking.market?.market_key,
        reasonId: booking.reason?.id,
    });

    // Filter out PRN providers that do not have availability in the range provided
    const appointmentGroupingsFiltered = useMemo(
        () =>
            appointmentsGroupings.filter(
                ({ provider }) =>
                    !provider.isprn ||
                    getFirstNextAppointment(
                        prnNextAvailableAppointments,
                        booking.department,
                        booking.market,
                        provider.providerid,
                        booking.reason
                    )
            ),
        [appointmentsGroupings, booking.market, booking.department, booking.reason, prnNextAvailableAppointments]
    );

    const reasons = useMemo(() => {
        if (!booking.department || !providers || !allReasons) {
            return [];
        }

        const filteredReasons = filterReasonsByProviders(allReasons, providers, booking.departmentAndRelatedDepartments);

        setSchedulingUnavailableReason(
            filteredReasons.length === 0 && booking.isExistingPatient === false ? 'NO_REASONS_NEW_PATIENTS' : null
        );

        return filteredReasons;
    }, [
        booking.department,
        booking.departmentAndRelatedDepartments,
        booking.isExistingPatient,
        providers,
        allReasons,
        setSchedulingUnavailableReason,
    ]);

    useEffect(() => {
        if (markets && !booking.market) {
            const market = markets.find(({ market_key }) => market_key === widgetOptions.marketKey);
            setBookingField({ market });
            setSchedulingUnavailableReason(!market ? 'NO_MARKET' : null);
        }
    }, [setBookingField, markets, widgetOptions.marketKey, booking.market, setSchedulingUnavailableReason]);

    useEffect(() => {
        if (departments && !booking.department) {
            const department = departments.find(({ departmentid }) => departmentid === widgetOptions.departmentId);
            setBookingField({ department });
            setSchedulingUnavailableReason(!department ? 'NO_DEPARTMENT' : null);
        }
    }, [setBookingField, departments, widgetOptions, booking.department, setSchedulingUnavailableReason]);

    useEffect(() => {
        if (reasons.length > 0 && !booking.reason) {
            const defaultReason = getDefaultReason(reasons, booking.isExistingPatient);
            const initialReason = reasons.find(({ id }) => id === widgetOptions.reasonId);
            setBookingField({ reason: initialReason ?? defaultReason });
        }
    }, [setBookingField, reasons, booking.isExistingPatient, booking.reason, widgetOptions.reasonId]);

    const handleSelectAppointment = (appointment: Appointment) => {
        const provider = booking.providersAndCareTeams.find(({ providerid }) => providerid === appointment.providerid);

        // This property will be used to help identify the difference between the first day in
        // the widget scheduler and the day of the appointment that was selected.
        const scheduledDaysOut = differenceInCalendarDays(new Date(appointment.date), booking.date);

        addCountlyEvent({
            key: 'selectSlot',
            segmentation: {
                appointmentId: appointment.appointmentid,
                appointmentTypeId: appointment.appointmenttypeid,
                appointmentSlotOrigin: 'widget-location',
                providerId: appointment.providerid,
                providerName: provider?.displayname,
                scheduledDaysOut,
                time: appointment.starttime,
                isCareTeam: 'No',
                isPrn: provider?.isprn,
            },
        });
        onSelectAppointment(appointment, booking);
    };

    const isFetching =
        isDepartmentsFetching ||
        isAppointmentsFetching ||
        isReasonsFetching ||
        isProvidersFetching ||
        isMarketsFetching ||
        isNextAvailableFetching;

    return (
        <Styled.Container>
            <Styled.Title>Book Appointment</Styled.Title>
            <Styled.EmergencyNotice>
                In case of emergency or life threatening illness, call 911 or go to your local ER
            </Styled.EmergencyNotice>
            <Styled.FieldsWrapper>
                <IsExistingPatientField />
                <ReasonField reasons={reasons} isReasonsFetching={isReasonsFetching} />
            </Styled.FieldsWrapper>

            <DatesNavigation isAppointmentsFetching={isAppointmentsFetching} />

            {!widgetOptions.enabledForScheduling && schedulingNotAvailableReason ? (
                <SchedulingNotAvailable reason={schedulingNotAvailableReason} />
            ) : (
                <Styled.RelativeContainer>
                    {isFetching ? (
                        <Styled.SpinnerContainer>
                            <Styled.SpinnerWrapper>
                                <CenteredSpinner />
                            </Styled.SpinnerWrapper>
                        </Styled.SpinnerContainer>
                    ) : null}
                    <Styled.AppointmentsContainer $loading={isFetching}>
                        {appointmentGroupingsFiltered.map((group) => (
                            <AppointmentsCard
                                key={`${group.provider.providerid}-${group.department.departmentid}`}
                                appointmentsGroup={group}
                                isCareTeamMember={false}
                                isAppointmentsFetching={isAppointmentsFetching}
                                onSelectAppointment={handleSelectAppointment}
                            />
                        ))}
                    </Styled.AppointmentsContainer>
                </Styled.RelativeContainer>
            )}
        </Styled.Container>
    );
};

export { LocationWidget };
