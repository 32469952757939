import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    background-color: ${({ theme }) => theme.vui.component.tag.default.backgroundColor};
    border-radius: 0.25rem;
    gap: 0.25rem;
    padding: 0.125rem 0.5rem;
`;

const Content = styled(Text).attrs(() => ({ type: 'caption1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

export { Container, Content };
