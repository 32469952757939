import { FC, ReactNode } from 'react';

import { Banner, Props as BannerProps } from 'components/banner';
import * as Styled from './styles';

interface Props extends BannerProps {
    subtitle?: ReactNode;
}

export const VisitTypeBanner: FC<Props> = ({ title, subtitle, icon: Icon, variant = 'default', ...bannerProps }) => (
    <Banner
        data-testid="visit-type-banner"
        title={title ? <Styled.Title data-testid="visit-type-banner-title">{title}</Styled.Title> : null}
        icon={Icon}
        variant={variant}
        iconSize={1.25}
        {...bannerProps}
    >
        {subtitle ? <Styled.Content data-testid="visit-type-banner-subtitle">{subtitle}</Styled.Content> : null}
    </Banner>
);
