import { prop, toPairs, keys } from 'ramda';

import { HUBSPOT_PROVIDER_IMAGE_DEFAULT, HUBSPOT_PROVIDER_SPECIALTY_DEFAULT } from 'constants/hubspot';
import { LINKS } from 'constants/links';
import { Provider, AceProvider, HubspotProvider, HubspotObject, Department, Reason, Market } from 'types';

const castToBoolean = (input: string | boolean): boolean => (typeof input === 'string' ? input === 'true' : input);

const providerFactory = (provider: AceProvider, hubspotProvider: HubspotProvider | undefined): Provider => ({
    ansinamecode: provider.ansinamecode,
    ansispecialtycode: provider.ansispecialtycode,
    billable: castToBoolean(provider.billable),
    createencounteroncheckin: castToBoolean(provider.createencounteroncheckin),
    displayname: getProviderDisplayName(provider, hubspotProvider),
    entitytype: provider.entitytype,
    firstname: provider.firstname,
    hideinportal: castToBoolean(provider.hideinportal),
    homedepartment: provider.homedepartment,
    isprn: provider.isprn,
    lastname: provider.lastname,
    npi: provider.npi ? Number(provider.npi) : undefined,
    providerid: Number(provider.providerid),
    providertype: provider.providertype,
    providertypeid: provider.providertypeid,
    providerusername: provider.providerusername,
    schedulingname: provider.schedulingname,
    sex: provider.sex,
    specialty: hubspotProvider?.serviceLine?.name ?? HUBSPOT_PROVIDER_SPECIALTY_DEFAULT,
    specialtyid: hubspotProvider?.serviceLine?.id ?? undefined,
    thumbnailUrl: hubspotProvider?.thumbnail?.url ?? HUBSPOT_PROVIDER_IMAGE_DEFAULT,
    hubspot: hubspotProvider,
});

const hubspotProviderFactory = ({ values, path }: HubspotObject): HubspotProvider => {
    return {
        firstName: values.first_name,
        displayName: values.display_name,
        credential: values.credential,
        bio: values.bio,
        thumbnail: values.headshot_image ?? undefined,
        npi: Number(values.npi),
        primarySpeciality: values.primary_specialty,
        lastName: values.last_name,
        marketKey: values.market_region_key,
        path: `${LINKS.villageMedical.providerBase}/${path}`,
        serviceLine: values.service_line,
    };
};

const getAllowedAppointmentTypeIds = (reason: Reason, market: Market, departmentId: number) => {
    if (!reason || !market) {
        return [];
    }

    if (reason.restrict_search) {
        const reasonDepartmentConfig = reason.departmentConfigs[departmentId] || {};
        const providersByAppointmentTypeId = reasonDepartmentConfig.providerIdsByAppointmentTypeId || {};

        return keys(providersByAppointmentTypeId).map((appointmentTypeId) => Number(appointmentTypeId));
    } else {
        return reason.is_in_person ? market?.appt_type_ids_in_person : market?.appt_type_ids_virtual;
    }
};

const getAllowedProviders = (
    departmentAndRelatedDepartments: Department[],
    reason: Reason,
    providers: Provider[]
): [Department, Provider[]][] => {
    return departmentAndRelatedDepartments.reduce<[Department, Provider[]][]>((accumulator, department) => {
        const providerMappingsById = reason.departmentConfigs[department.departmentid]?.providerIdsMappings;

        const filteredProviders: Provider[] = providers.filter(
            ({ providerid }) =>
                !!providerMappingsById && !!providerMappingsById[providerid] && department.providerlist.includes(providerid)
        );

        if (filteredProviders.length > 0) {
            return [...accumulator, [department, filteredProviders]];
        }

        return accumulator;
    }, []);
};

const getProviderCredential = (credentials: string | undefined): string => {
    const allowedCredentials = ['MD', 'DO'];
    return allowedCredentials.find((allowedCredential) => credentials?.includes(allowedCredential)) ?? '';
};

const getProviderDisplayName = (aceProvider: AceProvider, hubspotProvider: HubspotProvider | undefined): string => {
    if (aceProvider.entitytype === 'Non-person entity') {
        return 'Nurse or Medical Assistant Visit';
    }

    if (hubspotProvider?.firstName && hubspotProvider.lastName) {
        const fullName = `${hubspotProvider.firstName} ${hubspotProvider.lastName}`;
        const credential = getProviderCredential(hubspotProvider.credential);
        return credential ? `${fullName}, ${credential}` : fullName;
    }

    if (aceProvider.firstname && aceProvider.lastname) {
        const fullName = `${aceProvider.firstname} ${aceProvider.lastname}`;
        const credential = getProviderCredential(aceProvider.providertypeid);
        return credential ? `${fullName}, ${credential}` : fullName;
    }

    return '(No name)';
};

const filterProvidersByAppointmentTypeId = (
    reason: Reason,
    providersToFilter: Provider[],
    departmentId: number
): Record<string, number[]> | null => {
    const reasonDepartmentConfig = reason.departmentConfigs[departmentId] ?? null;

    if (!reasonDepartmentConfig) {
        return null;
    }

    const { providerIdsByAppointmentTypeId } = reasonDepartmentConfig;
    const providersIdsToFilter = providersToFilter.map(prop('providerid'));

    return toPairs(providerIdsByAppointmentTypeId).reduce<Record<string, number[]>>(
        (accumulator, [appointmentTypeId, providersIds]) => {
            const filteredProvidersIds = providersIdsToFilter.filter((id) => providersIds.includes(id));

            if (filteredProvidersIds.length > 0) {
                return { ...accumulator, [appointmentTypeId]: filteredProvidersIds };
            }

            return accumulator;
        },
        {}
    );
};

export {
    getAllowedAppointmentTypeIds,
    filterProvidersByAppointmentTypeId,
    providerFactory,
    hubspotProviderFactory,
    getAllowedProviders,
    getProviderDisplayName,
};
