import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';

import { aceApi } from 'api/ace';
import type { DepartmentApi, Booking } from 'types';

const useAceDepartments = (booking: Booking): UseQueryResult<DepartmentApi[]> => {
    const plainParams = {
        providerlist: true,
        showalldepartments: booking.market?.show_all_departments,
    };

    return useQuery({
        queryKey: ['ace-departments', plainParams, booking.market?.market_key],
        queryFn: async (): Promise<DepartmentApi[]> => {
            const { data } = await aceApi.get<{ departments: DepartmentApi[] }>(
                `${booking.market?.market_key}/villagefamilypractice/athena/departments`,
                { params: plainParams }
            );

            return data.departments;
        },
        enabled: Boolean(booking.market?.market_key && booking.isExistingPatient !== undefined),
    });
};

export { useAceDepartments };
