import type { FC } from 'react';
import { Tooltip, TooltipProps } from '@village/ui';

import * as Styled from './styles';

interface Props {
    placement?: TooltipProps['placement'];
}

export const PrnBadge: FC<Props> = ({ placement }) => (
    <Styled.Container data-testid="prn-badge">
        <Styled.Content>Floating provider</Styled.Content>
        <Tooltip content="This provider does not regularly practice at this location." placement={placement} />
    </Styled.Container>
);
