import { Datadog } from '@village/tools';

import { appConfig } from './app-config';
import { AppConfig } from 'types';

const { datadogLogs, initLogs } = Datadog.setupDatadogLogs();
const { datadogRum, initRum } = Datadog.setupDatadogRum();

const initDatadog = (
    { logsClientToken, rumClientToken, rumApplicationId, enableSessionRecording, service }: AppConfig['datadog'],
    context?: object
): void => {
    if (logsClientToken) {
        initLogs({
            clientToken: logsClientToken,
            service: service,
            env: appConfig.environment,
            version: appConfig.version,
            silentMultipleInit: true,
        });
    }

    if (rumApplicationId && rumClientToken) {
        initRum({
            silentMultipleInit: true,
            applicationId: rumApplicationId,
            clientToken: rumClientToken,
            service: service,
            env: appConfig.environment,
            version: appConfig.version,
            telemetrySampleRate: 0,
            allowedTracingUrls: [appConfig.aceApiBaseUrl],
            trackResources: true,
            /**
             * IMPORTANT
             * Make sure to check with product team
             * before enabling session recording
             * or changing the rates
             */
            sessionReplaySampleRate: 0,
            sessionSampleRate: 20,
            enableSessionRecording: false,
        });
    }

    if (enableSessionRecording) {
        datadogRum.startSessionReplayRecording();
    }

    if (context) {
        datadogRum.setGlobalContext(context);
    }
};

export { initDatadog, datadogLogs, datadogRum };
